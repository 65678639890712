<template>
    <div class="max-w-3xl mx-auto my-5 border rounded-2xl px-6 py-6 bg-white dark:bg-gray-600 dark:border-gray-600">
      <h2 class="text-2xl font-semibold mb-5 dark:text-gray-100">Update Your User Info</h2>
      <form @submit.prevent="editMode && updateProfile()">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- User Information -->
          <div class="md:col-span-2">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Username</label>
            <input type="text" v-model="user.username" disabled class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">First Name</label>
            <input type="text" v-model="user.firstName" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Last Name</label>
            <input type="text" v-model="user.lastName" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Initials</label>
            <input type="text" v-model="user.initials" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Email Address</label>
            <input type="email" v-model="user.email" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Cell Phone</label>
            <input type="email" v-model="user.cellEmail" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Mobile Phone</label>
            <input type="tel" v-model="user.mobilePhone" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" @blur="validatePhone" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <p v-if="phoneError && editMode" class="text-red-500 text-xs mt-1">{{ phoneError }}</p>
          </div>
          <!-- Password Change -->
          <div class="md:col-span-2">
            <h3 class="text-xl font-semibold mt-8 mb-5 dark:text-gray-100">Change Password (Leave blank if no password change desired)</h3>
          </div>
          <div class="md:col-span-2">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">New Password</label>
            <input type="password" v-model="user.newPassword" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          </div>
          <div class="md:col-span-2">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Confirm Password</label>
            <input type="password" @blur="validatePasswords" v-model="user.confirmPassword" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <p v-if="passwordError && editMode" class="text-red-500 text-xs mt-1">{{ passwordError }}</p>
          </div>
          <div class="md:col-span-2 text-right">
            <button v-if="!editMode" @click="toggleEditMode" class="dark:text-gray-600 dark:hover:text-gray-100 dark:bg-yellow-300 dark:hover:bg-yellow-600 mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">
              Edit
            </button>
            <button v-else @click="toggleEditMode" class="mt-6 px-6 py-2 mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Cancel
            </button>
            <button v-if="editMode" type="submit" class="mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">Update Profile</button>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        user: {
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          initials: '',
          cellEmail: '',
          mobilePhone: '',
          newPassword: '',
          confirmPassword: ''
        },
        editMode: false,
        cellPhoneError: '',
        phoneError: '',
        passwordError: '',
      };
    },
    methods: {
      toggleEditMode() {
        this.editMode = !this.editMode;
        if (!this.editMode) {
          this.fetchUserInfo(); // Restore original data on cancel
          this.newPassword= '';
          this.confirmPassword= '';
        }
      },
      updateProfile() {
        if (this.validatePhone() && this.validatePasswords()) {
          const userId = localStorage.getItem('user_id'); // Assuming you store user ID in localStorage
          axios.put(`extuser/${userId}`, {
            user: this.user,
            shifts: this.shifts
          })
            .then(response => {
              // Handle successful update
              alert('Profile successfully updated!');
              this.editMode = false; // Exit edit mode
              this.fetchUserInfo(); // Optionally fetch updated info
            })
            .catch(error => {
              // Handle error
              console.error('There was an error updating the profile:', error);
              alert('Failed to update profile.');
            });
        }
      },
      validatePhone() {
        const re = /^(\d{3}-\d{3}-\d{4}|\d{10})$/;
        if (!re.test(this.user.mobilePhone)) {
          this.phoneError = 'Invalid phone format. Use 000-000-0000 or 0000000000.';
          return false;
        }
        // if (!re.test(this.user.cellPhone)) {
        //   this.cellPhoneError = 'Invalid phone format. Use 000-000-0000 or 0000000000.';
        //   return false;
        // }
        this.phoneError = '';
        // this.cellPhoneError = '';
        return true;
      },
      validatePasswords() {
        if (this.user.newPassword !== this.user.confirmPassword) {
          this.passwordError = 'Passwords do not match.';
          return false;
        }
        this.passwordError = '';
        return true;
      },
      fetchUserInfo() {
        const user_id = localStorage.getItem('user_id');
        axios.get(`extuser/profile/${user_id}`) // Adjust the endpoint as needed for your API
          .then(response => {
            this.user.firstName = response.data.firstname;
            this.user.lastName = response.data.lastname;
            this.user.email = response.data.email;
            this.user.username = response.data.username;
            this.user.initials = response.data.initials;
            this.user.cellEmail = response.data.cellemail;
            this.user.address = response.data.address;
            this.user.mobilePhone = response.data.phone;
          })
          .catch(error => {
            console.error('There was an error fetching the user data:', error);
          });
      }
    },
    mounted() {
      this.fetchUserInfo();
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  