<template>
  <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-70 z-50 flex justify-center items-center p-4">
    <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
      <h2 class="text-lg font-semibold dark:text-gray-100 mb-4">Add Reminder To Ticket #{{ sourceTicketId }}</h2>
      <label for="tech" class="block text-sm font-medium text-gray-700 dark:text-gray-100 mb-2">Select Tech</label>
      <select id="tech" v-model="selectedTech" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100 mb-4">
        <option v-for="tech in techs" :key="tech.id" :value="tech.id">{{ tech.name }}</option>
      </select>

      <label for="day" class="block text-sm font-medium text-gray-700 dark:text-gray-100 mb-2">Day</label>
      <input type="date" id="day" v-model="day" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100 mb-4" />

      <label for="time" class="block text-sm font-medium text-gray-700 dark:text-gray-100 mb-2">Time</label>
      <input type="time" id="time" v-model="time" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100 mb-4" />

      <div class="flex items-center mb-4">
        <input type="checkbox" id="sendText" v-model="sendText" class="mr-2 rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100" />
        <label for="sendText" class="block text-sm font-medium text-gray-700 dark:text-gray-100">Send Text?</label>
      </div>

      <label for="description" class="block text-sm font-medium text-gray-700 dark:text-gray-100 mb-2">Description</label>
      <textarea id="description" v-model="description" rows="3" class="w-full p-2 rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100 mb-4"></textarea>

      <div class="flex justify-end space-x-2 mt-4">
        <button @click="closeModal" class="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded dark:bg-red-500 dark:hover:bg-red-700">Cancel</button>
        <button @click="saveReminder" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded dark:bg-yellow-500 dark:hover:bg-yellow-700">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    sourceTicketId: {
      type: Number,
      required: true
    },
    techs: {
      type: Array,
      required: true
    },
    currentTech: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      selectedTech: this.currentTech,
      day: '',
      time: '',
      sendText: false,
      description: '',
      // techs: [
      //   { id: 1, name: 'Gali, Mary' },
      //   { id: 2, name: 'Tech 2' },
      //   // Add more techs here
      // ]
    };
  },
  methods: {
    closeModal() {
      this.selectedTech = '';
      this.day = '';
      this.time = '';
      this.sendText = false;
      this.description = '';
      this.$emit('close');
    },
    saveReminder() {
      if (this.selectedTech && this.day && this.time && this.description) {
        this.$emit('complete', {
          ticketId: this.sourceTicketId,
          techId: this.selectedTech,
          day: this.day,
          time: this.time,
          sendText: this.sendText,
          description: this.description
        });
        this.closeModal();
      } else {
        alert('Please fill in all fields.');
      }
    }
  }
};
</script>
