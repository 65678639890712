<template>
  <div class="max-w-4xl mx-auto my-5 border rounded-2xl px-6 py-6 bg-white dark:bg-gray-600 dark:border-gray-600">
    <h2 class="text-2xl font-semibold mb-5 dark:text-gray-100">Update Your User Info</h2>
    <form @submit.prevent="editMode && updateProfile()">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- User Information -->
        <div class="md:col-span-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Username</label>
          <input type="text" v-model="user.username" disabled class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">First Name</label>
          <input type="text" v-model="user.firstName" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Last Name</label>
          <input type="text" v-model="user.lastName" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Initials</label>
          <input type="text" v-model="user.initials" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Email Address</label>
          <input type="email" v-model="user.email" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Cell Email</label>
          <input type="email" v-model="user.cellEmail" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Mobile Phone</label>
          <input type="tel" v-model="user.mobilePhone" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" @blur="validatePhone" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          <p v-if="phoneError && editMode" class="text-red-500 text-xs mt-1">{{ phoneError }}</p>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Notification Preference</label>
          <select v-model="user.notificationPreference" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <option>Email</option>
            <option>SMS</option>
            <option>Both</option>
          </select>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Email Preference</label>
          <select v-model="user.ticketemailpref" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <option value="0">None</option>
            <option value="1">New Only</option>
            <option value="2">Updates Only</option>
            <option value="3">New and Updates</option>
          </select>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Tickets Per Page</label>
          <select v-model="user.ticketsperpage" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </select>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Default Job Type</label>
          <select v-model="user.jobType" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <option v-for="jobtype in jobTypes" :key="jobtype.id"
                          :value="jobtype.id">
                          {{ jobtype.name }}
                        </option>
          </select>
        </div>
        <div class="md:col-span-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">AIM/Misc Default Job Description</label>
          <textarea type="text" v-model="user.miscjobtext" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm" :placeholder="'(ticket/email administration)'"></textarea>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">RC Username</label>
          <input type="text" v-model="user.rcusername" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm" :placeholder="'Your Username in Rocket.Chat'">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Role</label>
          <input type="text" v-model="user.role" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100" disabled>
        </div>
        <!-- Emergency Contact -->
        <div class="md:col-span-2">
          <h3 class="text-xl font-semibold mt-8 mb-5 dark:text-gray-100">Emergency Contact</h3>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Name</label>
          <input type="text" v-model="user.emergencyContact.name" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Relation</label>
          <input type="text" v-model="user.emergencyContact.relation" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Phone</label>
          <input type="tel" v-model="user.emergencyContact.phone" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>

        <!-- Shifts Management Section -->
        <div class="md:col-span-2">
          <h3 class="text-xl font-semibold mt-8 mb-5 dark:text-gray-100">Shift Management</h3>
        </div>
        <div class="md:col-span-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Shift Type</label>
          <select v-model="selectedShiftType" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
            <option v-for="shiftType in shiftTypes" :key="shiftType.id" :value="shiftType.id">{{ shiftType.name }}</option>
          </select>
        </div>
        <div class="md:col-span-2">
          <table class="min-w-full mt-5">
            <thead>
              <tr>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400">Day</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400">Start Time</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400">End Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(shift, index) in shifts" :key="shift.id">
                <td class="px-4 py-2">
                  <select v-model="shift.day_of_week" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                    <option v-for="day in weekdays" :key="day.id" :value="day.id">{{ day.day }}</option>
                  </select>
                </td>
                <td class="px-4 py-2">
                  <input type="time" v-model="shift.begin_time" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                </td>
                <td class="px-4 py-2">
                  <input type="time" v-model="shift.end_time" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                </td>
                <td class="px-4 py-2 text-right">
                  <button @click="removeShift(index)" class="text-red-600 hover:text-red-900" v-if="editMode">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-4">
            <button @click="addShift" class="text-green-600 hover:text-green-900" v-if="editMode">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
              </svg>
            </button>
          </div>
        </div>
        <!-- End of Shifts Management Section -->

        <!-- Password Change -->
        <div class="md:col-span-2">
          <h3 class="text-xl font-semibold mt-8 mb-5 dark:text-gray-100">Change Password (Leave blank if no password change desired)</h3>
        </div>
        <div class="md:col-span-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">New Password</label>
          <input type="password" v-model="user.newPassword" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div class="md:col-span-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Confirm Password</label>
          <input type="password" @blur="validatePasswords" v-model="user.confirmPassword" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          <p v-if="passwordError && editMode" class="text-red-500 text-xs mt-1">{{ passwordError }}</p>
        </div>
        <div class="md:col-span-2 text-right">
          <button v-if="!editMode" @click="toggleEditMode" class="dark:text-gray-600 dark:hover:text-gray-100 dark:bg-yellow-300 dark:hover:bg-yellow-600 mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">
            Edit
          </button>
          <button v-else @click="toggleEditMode" class="mt-6 px-6 py-2 mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Cancel
          </button>
          <button v-if="editMode" type="submit" class="mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">Update Profile</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      user: {
        username: '',
        firstName: '',
        lastName: '',
        role: '',
        initials: '',
        email: '',
        cellEmail: '',
        mobilePhone: '',
        notificationPreference: '',
        ticketemailpref: 0,
        ticketsperpage: 20,
        jobType: 2,
        miscjobtext:'',
        rcusername: '',
        shiftType: 1,
        emergencyContact: {
          name: '',
          relation: '',
          phone: '',
        },
        newPassword: '',
        confirmPassword: ''
      },
      jobTypes: [],
      shifts: [],
      shiftTypes: [],
      weekdays: [],
      selectedShiftType: 1,
      query: '',
      editMode: false,
      phoneError: '',
      passwordError: '',
    };
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (!this.editMode) {
        this.fetchUserInfo(); // Restore original data on cancel
        this.fetchJobTypes();
        this.fetchUserShifts();
        this.newPassword= '';
        this.confirmPassword= '';
      }
    },
    updateProfile() {
      if (this.validatePhone() && this.validatePasswords()) {
        const userId = localStorage.getItem('user_id'); // Assuming you store user ID in localStorage
        axios.put(`user/${userId}`, {
          user: this.user,
          shifts: this.shifts
        })
          .then(response => {
            // Handle successful update
            alert('Profile successfully updated!');
            this.editMode = false; // Exit edit mode
            this.fetchUserInfo(); // Optionally fetch updated info
            this.fetchJobTypes();
            this.fetchUserShifts();
          })
          .catch(error => {
            // Handle error
            console.error('There was an error updating the profile:', error);
            alert('Failed to update profile.');
          });
      }
    },
    validatePhone() {
      const re = /^(\d{3}-\d{3}-\d{4}|\d{10})$/;
      if (!re.test(this.user.mobilePhone)) {
        this.phoneError = 'Invalid phone format. Use 000-000-0000 or 0000000000.';
        return false;
      }
      this.phoneError = '';
      return true;
    },
    validatePasswords() {
      if (this.user.newPassword !== this.user.confirmPassword) {
        this.passwordError = 'Passwords do not match.';
        return false;
      }
      this.passwordError = '';
      return true;
    },
    fetchUserInfo() {
      const user_id = localStorage.getItem('user_id');
      axios.get(`user/profile/${user_id}`) // Adjust the endpoint as needed for your API
        .then(response => {
          this.user.username = response.data.username;
          this.user.firstName = response.data.firstname;
          this.user.lastName = response.data.lastname;
          this.user.initials = response.data.initials;
          this.user.email = response.data.email;
          this.user.cellEmail = response.data.cellemail;
          this.user.address = response.data.address;
          this.user.role = response.data.role_name;
          this.user.mobilePhone = response.data.phone;
          this.user.notificationPreference = response.data.notificationpreference;
          this.user.ticketemailpref = response.data.ticketemailpref;
          this.user.jobType = response.data.jobtype_id;
          this.user.ticketsperpage = response.data.ticketsperpage;
          this.user.miscjobtext = response.data.miscjobtext;
          this.user.rcusername = response.data.rcusername;
          this.user.emergencyContact.name = response.data.emergency_name;
          this.user.emergencyContact.relation = response.data.emergency_relation;
          this.user.emergencyContact.phone = response.data.emegency_phone;
          //for the address search function
          this.query = response.data.address;
        })
        .catch(error => {
          console.error('There was an error fetching the user data:', error);
        });
    },
    fetchUserShifts() {
      const user_id = localStorage.getItem('user_id');
      axios.get(`user/shifts/${user_id}`) // Adjust the endpoint as needed for your API
        .then(response => {
          // Assuming the response has a data object with user information
          console.log(response)
          this.shifts = response.data.shifts;
          this.shiftTypes = response.data.shifttypes;
          this.weekdays = response.data.weekdays;
          this.user.shiftType = this.shifts[0].shifttype_id;
        })
        .catch(error => {
          console.error('There was an error fetching the user data:', error);
        });
    },
    fetchJobTypes() {
      axios.get(`user/jobtypes/`) // Adjust the endpoint as needed for your API
        .then(response => {
          // Assuming the response has a data object with user information
          console.log(response)
          this.jobTypes = response.data;
        })
        .catch(error => {
          console.error('There was an error fetching the user data:', error);
        });
    },
    addShift() {
      this.shifts.push({
        id: null,
        user_id: null,
        day_of_week: this.weekdays[0].id,
        begin_time: '08:00:00',
        end_time: '16:00:00',
        shifttype_id: this.selectedShiftType,
        shifttype: this.shiftTypes.find(st => st.id === this.selectedShiftType)
      });
    },
    removeShift(index) {
      this.shifts.splice(index, 1);
    }
  },
  mounted() {
    this.fetchUserInfo();
    this.fetchJobTypes();
    this.fetchUserShifts();
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
