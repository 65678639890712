<template >
    <div class="dark:bg-gray-400">
        Time
    </div>
  </template>
  
  <script>
  
  </script>
  

<style scoped>
</style>