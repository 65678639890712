<template>
  <div
    class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-2xl dark:bg-gray-700"
  >
    <h2 class="text-l font-bold text-gray-900 mb-4 dark:text-gray-100">
      Related Tickets
    </h2>

    <div class="space-y-4">
      <div>
        <!-- Button to not attach anything and clear selection -->
        <button
          @click="clearSelection"
          class="mb-2 inline-flex items-center px-2 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600"
        >
          Do NOT Attach to Ticket
        </button>

        <!-- Related Tickets List -->
        <div class="space-y-2">
          <div
            v-for="ticket in paginatedTickets"
            :key="ticket.id"
            class="p-4 bg-gray-100 rounded-md flex justify-between items-center dark:bg-gray-800"
          >
            <span class="text-sm dark:text-gray-100">
              {{ ticket.submitted }} {{ ticket.id }} {{ ticket.tickettype }}:
              {{ ticket.firstname }} {{ ticket.lastname }} -
              <span class="font-bold italic">{{ ticket.requester }}</span
              ><br />
              <template v-if="ticket.description.includes(':')">
                {{ ticket.tickettype }}{{ ticket.description }}
              </template>
              <template v-else>
                {{ ticket.tickettype }}: {{ ticket.description }}:
              </template></span
            >
            <button
              @click="toggleTicket(ticket.id)"
              :class="{
                'bg-red-500 hover:bg-red-600': ticket.id === selectedTicketId,
                'bg-blue-500 hover:bg-blue-600': ticket.id !== selectedTicketId,
                'bg-gray-300 cursor-not-allowed hover:bg-gray-300': selectedTicketId != 0 && selectedTicketId != ticket.id
              }"
              :disabled="selectedTicketId != 0 && selectedTicketId != ticket.id"
              class="ml-4 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white"
            >
              <template v-if="ticket.id === selectedTicketId">
                <svg
                  class="w-4 h-4"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </template>
              <template v-else>Add</template>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-4">
      <button
        v-if="hasPreviousPage"
        :disabled="currentPage === 1"
        @click="currentPage -= 1"
        class="bg-gray-600 text-gray-100 rounded-lg px-3 py-1 mr-2 hover:bg-gray-800 text-sm dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300"
      >
        Previous
      </button>
      <button
        v-if="hasNextPage"
        :disabled="currentPage * pageSize >= relatedTickets.length"
        @click="currentPage += 1"
        class="bg-gray-600 text-gray-100 rounded-lg px-3 py-1 hover:bg-gray-800 text-sm dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300"
      >
        Next
      </button>
    </div>

    <div class="mt-8 flex justify-between">
      <button
        class="bg-gray-600 text-gray-100 rounded-lg px-5 py-2 hover:bg-gray-800 text-sm dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300"
        @click="emitClose()"
      >
        Cancel
      </button>
      <button
        @click="handleAttachTickets"
        class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
      >
        Attach Ticket
      </button>
      <!-- <button @click="next()" class="bg-red-500 text-white rounded-lg px-5 py-2 hover:bg-red-600">Next</button> -->
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, defineProps, computed, watch } from "vue";
import axios from "axios";

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
  attachTo: {
    type: Number,
    required:true
  }
});

const emit = defineEmits(["close", "handleRelatedTickets"]);
// const selectedTickets = ref([]);
const relatedTickets = ref([]);
const selectedTicketId = ref(0);

// Pagination
const currentPage = ref(1);
const pageSize = 6;

const emitClose = () => {
  emit("close");
};

onMounted(() => {
  fetchRelatedTickets();
});

watch(() => props.attachTo, (newVal) => {
  selectedTicketId.value = newVal;
}, { immediate: true });

async function fetchRelatedTickets() {
  selectedTicketId.value = props.attachTo;
  try {
    const response = await axios.get(
      `${axios.defaults.baseURL}ticket/related-tickets`,
      {
        params: {
          client_id: props.clientId,
        },
      }
    );
    // relatedTickets.value = response.data.map((ticket) => ({
    //   ...ticket,
    //   isSelected: false,
    // }));
    relatedTickets.value = response.data;
    relatedTickets.value.forEach((ticket) => (ticket.isSelected = false));
  } catch (error) {
    console.error("Error fetching related tickets:", error);
  }
}

function clearSelection() {
  relatedTickets.value.forEach((ticket) => (ticket.isSelected = false));
  // selectedTickets.value = [];
  selectedTicketId.value = 0;
}

function toggleTicket(ticketId) {
  // const ticket = relatedTickets.value.find((t) => t.id === ticketId);
  // if (ticket) {
  //   ticket.isSelected = !ticket.isSelected;
  //   if (ticket.isSelected) {
  //     selectedTickets.value.push(ticketId);
  //   } else {
  //     const index = selectedTickets.value.indexOf(ticketId);
  //     if (index !== -1) {
  //       selectedTickets.value.splice(index, 1);
  //     }
  //   }
  // }

  if (selectedTicketId.value === ticketId) {
    // If the ticket is already selected, deselect it and reset attachTo
    selectedTicketId.value = 0;
  } else {
    // Otherwise, select this ticket and update attachTo
    selectedTicketId.value = ticketId;
  }
}

function handleAttachTickets() {
  emit('handleRelatedTickets', selectedTicketId);
}

// Computed property for paginated tickets
const paginatedTickets = computed(() => {
  const startIndex = (currentPage.value - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return relatedTickets.value.slice(startIndex, endIndex);
});

const hasPreviousPage = computed(() => currentPage.value > 1);
const hasNextPage = computed(
  () => currentPage.value * pageSize < relatedTickets.value.length
);
</script>
