import axios from 'axios';

const API_URL = axios.defaults.baseURL + 'user/darkmode';

const ThemeService = {

  getApiUrl() {
    return `${axios.defaults.baseURL}user/darkmode`;
  },
  toggleDarkMode() {
    const currentTheme = document.documentElement.classList.contains('dark') ? 'light' : 'dark';
    this.setDarkMode(currentTheme === 'dark');
  },

  getCurrentMode() {
    const currentTheme = localStorage.getItem('darkmode');
    return currentTheme === 'true';
  },

  loadThemePreference() {
    axios.get(this.getApiUrl())
      .then(response => {
        const darkMode = response.data.darkmode;
        if (darkMode == 1) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('darkmode', darkMode == 1 ? true: false)
      })
      .catch(error => {
        console.error('There was an error fetching the user\'s theme preference:', error);
      });
  },

  setDarkMode(isDark) {
    const id = localStorage.getItem('user_id');
    axios.put(`${axios.defaults.baseURL}user/${id}/darkmode`, { darkmode: isDark? 1:0 })
      .then(() => {
        if (isDark) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('darkmode', isDark ? true: false)
      })
      .catch(error => {
        console.error('There was an error updating the theme preference:', error);
      });
  },
  };
  
  export default ThemeService;
  