// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/swirl_crop.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.v-enter-active, .v-leave-active {\n  transition: opacity 3s;\n}\n.v-enter, .v-leave-to {\n  opacity: 0;\n}\n.btn-custom-color {\n  background-color: #fbbb05; /* Your desired color */\n  color: white; /* Set text color to ensure visibility */\n}\n.txt-custom-color {\n  color: #fbbb05; /* Set text color to ensure visibility */\n}\n.btn-custom-color:hover {\n  background-color: #e0a800; /* Slightly darker for hover effect */\n}\n.login-custom-color {\n  background-color: #696969; /* Your desired color */\n  color: white; /* Set text color to ensure visibility */\n}\n.login-custom-color:hover {\n  background-color: #4c4c4c; /* Slightly darker for hover effect */\n}\n\n\n/* Add styles for the disabled state */\n.btn-custom-color:disabled {\n  background-color: #fbbb05; /* Maintain the background color */\n  color: white; /* Maintain the text color */\n  opacity: 0.7; /* Optional: Lower opacity to indicate it's disabled */\n  cursor: not-allowed; /* Optional: Change cursor to indicate it's not clickable */\n}\n.backimg {\n  opacity: 0.30;\n  position: fixed; /* Changed from relative to fixed */\n  top: 0; /* Position it at the top of the viewport */\n  left: 0; /* Position it at the left of the viewport */\n  width: 100vw; /* Span the entire viewport width */\n  height: 100vh; /* Span the entire viewport height */\n  z-index: -1; /* Place it behind other content */\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center; /* Center the background */\n  background-color: #FFF;\n  background-size: cover; /* Cover the entire area of the div */\n}\n.content-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center; /* Center vertically */\n  align-items: center; /* Center horizontally */\n  height: 100vh; /* Full viewport height */\n  position: relative; /* Ensure it's positioned above the background */\n  z-index: 1; /* Higher than the background */\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
