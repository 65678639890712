<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center"
  >
    <div class="bg-white p-4 rounded-lg max-w-4xl w-full dark:bg-gray-700">
      <textarea
        v-model="text"
        class="w-full h-96 p-2 border border-gray-300 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-800"
      ></textarea>
      <div class="flex justify-end space-x-2 mt-4">
        <button
          @click="finishEdit()"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Finish
        </button>
        <button
          @click="closeModal()"
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    initialText: String,
    activeTextarea: String,
  },
  data() {
    return {
      // Initialize the local text with the prop value
      text: this.initialText,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    finishEdit() {
      this.$emit("complete", {
        updatedText: this.text,
        key: this.activeTextarea,
      });
    },
  },
  watch: {
    // Whenever the initialText prop changes, update the local text
    initialText(newVal) {
      this.text = newVal;
    },
  },
};
</script>
