<template>
    <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-70 z-50 flex justify-center items-center p-4">
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <h2 class="text-lg font-semibold dark:text-gray-100 mb-4">Merge Tickets</h2>
        <p class="mb-4 font-semibold dark:text-gray-100">Merge Ticket #{{ sourceTicketId }} into #<input type="text" v-model="targetTicketId" class="w-1/2 inline-block p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100 dark:placeholder-gray-300" placeholder="Target Ticket ID" /></p>
        <label for="reason" class="block text-sm font-medium text-gray-700 dark:text-gray-100 mb-2">Reason</label>
        <textarea id="reason" v-model="reason" rows="3" class="w-full p-2 rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100"></textarea>
        <div class="flex justify-end space-x-2 mt-4">
          <button @click="closeModal" class="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded dark:bg-red-500 dark:hover:bg-red-700">Cancel</button>
          <button @click="mergeTickets" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded dark:bg-yellow-500 dark:hover:bg-yellow-700">Merge</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },
      sourceTicketId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        targetTicketId: '',
        reason: ''
      };
    },
    methods: {
      closeModal() {
        this.targetTicketId = '';
        this.reason = '';
        this.$emit('close');
      },
      mergeTickets() {
        if (this.targetTicketId && this.reason) {
          this.$emit('complete', {
            sourceTicketId: this.sourceTicketId,
            targetTicketId: this.targetTicketId,
            reason: this.reason
          });
          this.closeModal();
        } else {
          alert('Please fill in all fields.');
        }
      }
    }
  };
  </script>