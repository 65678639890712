<template>
    <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-70 z-50 flex justify-center items-center p-4">
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <h2 class="text-lg font-semibold dark:text-gray-100 mb-4">Add New User</h2>
        <div class="space-y-4">
          <div v-if="errors.firstName" class="text-red-500 text-xs">{{ errors.firstName }}</div>
          <input type="text" v-model="newUser.firstName" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-600 dark:text-gray-100" placeholder="First Name" :class="{ 'dark:border-red-600 border-red-600': errors.firstName }"/>
          <div v-if="errors.lastName" class="text-red-500 text-xs">{{ errors.lastName }}</div>
          <input type="text" v-model="newUser.lastName" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-600 dark:text-gray-100" placeholder="Last Name" :class="{ 'dark:border-red-600 border-red-600': errors.lastName}"/>
          <div v-if="errors.email" class="text-red-500 text-xs">{{ errors.email }}</div>
          <input type="email" v-model="newUser.email" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-600 dark:text-gray-100" placeholder="Email" :class="{ 'dark:border-red-600 border-red-600': errors.email}"/>
          <div v-if="errors.phone" class="text-red-500 text-xs">{{ errors.phone }}</div>
          <input type="text" v-model="newUser.phone" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-600 dark:text-gray-100" placeholder="Phone Number" :class="{ 'dark:border-red-600 border-red-600': errors.phone}"/>
        </div>
        <div class="flex justify-end space-x-2 mt-4">
          <button @click="addUser" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-blue-400 dark:hover:bg-blue-600">Add</button>
          <button @click="closeModal()" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded dark:bg-red-400 dark:hover:bg-red-600">Close</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
    isVisible: Boolean,
  },
  data() {
    return {
      newUser: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
        errors: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
        },
    };
  },
  methods: {
    closeModal() {
      this.newUser = { firstName: '', lastName: '', email: '', phone: '' };
      this.errors = { firstName: '', lastName: '', email: '', phone: '' };
      this.$emit("close");
    },
    validateForm() {
      let valid = true;

      // First name validation: must not be empty
      if (!this.newUser.firstName) {
        this.errors.firstName = 'First name is required';
        valid = false;
      } else {
        this.errors.firstName = null;
      }

      // Last name validation: must not be empty
      if (!this.newUser.lastName) {
        this.errors.lastName = 'Last name is required';
        valid = false;
      } else {
        this.errors.lastName = null;
      }

      // Email validation: must be in correct format
      const emailPattern = /^[^@]+@\w+(\.\w+)+\w$/;
      if (!this.newUser.email || !emailPattern.test(this.newUser.email)) {
        this.errors.email = 'Please enter a valid email address';
        valid = false;
      } else {
        this.errors.email = null;
      }

      // Phone number validation: must be in correct format
      // Example pattern: validate US phone number format
      const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (!this.newUser.phone || !phonePattern.test(this.newUser.phone)) {
        this.errors.phone = 'Please enter a valid phone number';
        valid = false;
      } else {
        this.errors.phone = null;
      }

      return valid;
    },
    addUser() {
      // Display the alert with the user information
      // const confirmation = confirm(`New User will be Added:\nName: ${this.newUser.firstName} ${this.newUser.lastName}\nEmail: ${this.newUser.email}\nPhone: ${this.newUser.phone}\n\nPress OK to add or Cancel to edit.`);
      // if (confirmation) {
      //   // Logic to add the user to the user table goes here
      //   this.$emit("complete", {
      //       newUser: this.newUser
      //   });
      //   // Reset the newUser object
      //   this.newUser = { firstName: '', lastName: '', email: '', phone: '' };
      // } else {
        
      // }
      if (this.validateForm()) {
        this.$emit("complete", {
            newUser: this.newUser
        });
        // Reset the newUser object
        this.newUser = { firstName: '', lastName: '', email: '', phone: '' };
        this.errors = { firstName: '', lastName: '', email: '', phone: '' };
      }
    }
  },
};
  </script>
  