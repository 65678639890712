export default {
    mounted(el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        // Check that click was outside the el and his children
        if (!(el === event.target || el.contains(event.target))) {
          // If the clicks are outside, execute the expression
          binding.value(event);
        }
      };
      // Use capture phase to handle the event first
      document.body.addEventListener('click', el.clickOutsideEvent, true);
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent, true);
    },
  };
  