// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/swirl_crop.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.backimg[data-v-7ba5bd90] {\nopacity: 0.30;\nposition: fixed; /* Changed from relative to fixed */\ntop: 0; /* Position it at the top of the viewport */\nleft: 0; /* Position it at the left of the viewport */\nwidth: 100vw; /* Span the entire viewport width */\nheight: 100vh; /* Span the entire viewport height */\nz-index: -1; /* Place it behind other content */\nbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center; /* Center the background */\nbackground-color: #FFF;\nbackground-size: cover; /* Cover the entire area of the div */\n}\n\n/* .backimg {\n/* Existing styles\n} */\n.router-view-content[data-v-7ba5bd90] {\nmin-height: calc(100vh - 64px); /* This assumes the nav bar is 64px tall */\nwidth: 100%;\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
