<template>
  <div class="max-w-full mx-auto my-5 border rounded-2xl px-6 py-6 bg-white dark:bg-gray-600 dark:border-gray-600">
    <h2 class="text-2xl font-semibold mb-5 dark:text-gray-100 text-center">Create a New Ticket</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div class="space-y-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- Submitted Date -->
            <div class="md:col-span-2">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Submitted Date</label>
              <input type="datetime-local" v-model="ticket.submissionDateTime" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
            </div>

            <!-- Client -->
            <!-- <div class="md:col-span-2">
              <div class="md:col-span-2 flex items-center">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-100 mr-4">Clients</label>
                <div class="flex items-center mr-4">
                  <input type="radio" id="activeClients" value="true" v-model="activeClients" @change="fetchClients" class="form-radio text-blue-600 dark:text-gray-900">
                  <label for="activeClients" class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-100">Active</label>
                </div>
                <div class="flex items-center">
                  <input type="radio" id="inactiveClients" value="false" v-model="activeClients" @change="fetchClients" class="form-radio text-blue-600 dark:text-gray-900">
                  <label for="inactiveClients" class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-100">Inactive</label>
                </div>
              </div>
              <div class="md:col-span-2">
                <select v-model="ticket.company_id" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                  <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.companyname }}</option>
                </select>
              </div>
            </div> -->

            <div class="md:col-span-2" v-click-outside="closeClientDropdown">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100 mr-4">Client</label>
              <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                placeholder="Client Name" v-model="ticket.companyname"
                @focus="showClientDropdown = true" />
              <ul v-if="showClientDropdown"
                class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                <li v-for="client in filteredClients" :key="client.id" @click="setClient(client)"
                  class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                  {{ client.companyname }}
                </li>
              </ul>
            </div>

            
            <!-- Type -->
            <!-- <div class="md:col-span-2">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Type</label>
              <select v-model="ticket.tickettypeid" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                <option v-for="type in ticketTypes" :key="type.id" :value="type.id">{{ type.description }}</option>
              </select>
            </div> -->

            <div class="md:col-span-2" v-click-outside="closeTypeDropdown">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Type</label>
              <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                placeholder="Type" v-model="ticket.tickettype" @focus="showReqTypeDropdown = true" />
              <ul v-if="showReqTypeDropdown"
                class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                <li v-for="reqtype in filteredReqTypes" :key="reqtype.id" @click="setReqType(reqtype)"
                  class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                  {{ reqtype.description }}
                </li>
              </ul>
            </div>

            <!-- Requester -->
            <div class="md:col-span-2 mt-2">
              <div
                class="p-4 bg-white shadow-md rounded-md border-2 border-blue-900 relative dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                <div class="absolute top-0 left-0 right-0 bg-blue-900 px-3 py-1 flex items-center dark:bg-gray-800">
                  <div class="flex items-center space-x-2">
                    <h4 class="text-white font-bold text-sm">Requested By</h4>
                  </div>
                </div>
                <div class="md:col-span-2 mt-4">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                    <div class="flex space-x-2">
                      <div class="flex-grow" v-click-outside="closeReqDropdown">
                        <!-- <label class="block text-sm font-medium text-gray-700">Requested By</label> -->
                        <input type="text"
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                          placeholder="Select Requester" v-model="ticket.requester"
                          @focus="showReqDropdown = true" />
                        <ul v-if="showReqDropdown"
                          class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                          <li v-for="req in filteredRequesters" :key="req.id" @click="confirmRequesterChange(req)"
                            class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                            {{ req.name }}
                          </li>
                        </ul>
                      </div>
                      <div class="shrink-0">
                        <button @click="showAddUserModal = true"
                          class="mt-2 bg-gray-400 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900">
                          <!-- Plus icon -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <RequesterConfirmationModal v-if="showReqConfirmationModal"
                      @confirm="handleReqConfirmation(true)"
                      @cancel="handleReqConfirmation(false)" />
                    <!-- <div>
                      <label class="block text-sm font-medium text-gray-700">Requested By</label>
                      <select
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900"
                        v-model="currentTicket.clientLocation">
                        <option selected>Set Location</option>
                        <option v-for="address in currentTicket.additionalAddresses" :key="address.id"
                          :value="address.id">
                          {{ address.name ? address.name : address.address }}
                        </option>
                      </select>
                    </div> -->
                    <div>
                      <div class="flex justify-center mt-2">
                        <label class="block font-medium text-gray-700 text-xs dark:text-gray-100 mt-2 mr-2">Add a
                          Watcher?</label>
                        <button @click="toggleWatcher"
                          class="mt-1 bg-gray-400 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900 transition-transform duration-300"
                          :class="{ 'transform rotate-180': showWatcher }">
                          <svg
                            :class="{ 'fill-current text-gray-100': !showWatcher, 'fill-current text-blue-500': showWatcher }"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="h-4 w-4">
                            <path
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                          </svg>
                        </button>
                      </div>
                      <transition name="slide-fade">
                        <div v-click-outside="closeWatcherDropdown">
                          <input v-if="showWatcher" type="email"
                            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100 transition-all duration-300 ease-in-out"
                            placeholder="Enter Requester Email if not in System" @focus="toggleWatcherDropdown"
                            v-model="watchersInput"/>
                          <ul v-if="showWatcherDropdown"
                            class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                            <li v-for="watcher in filteredWatchers" :key="watcher.id" @click="addWatcher(watcher)"
                              class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                              {{ watcher.name }}
                            </li>
                          </ul>
                          <div class="mt-1 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">
                            <div v-for="watcher in ticket.watchersList.filter(w => w.visible)" :key="watcher.id"
                              class="flex justify-between items-center border border-gray-200 rounded-full p-1 text-xs dark:border-gray-900 dark:bg-gray-400">
                              {{ watcher.name }}
                              <button @click="removeWatcher(watcher)"
                                class="ml-2 bg-red-600 hover:bg-red-700 text-white p-1 rounded-full text-xs">
                                &times;
                              </button>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddRequesterModal :isVisible="showAddUserModal" @close="showAddUserModal = false" @complete="handleAddUserCompletion" />
            <!-- Email, Phone -->
            <div class="flex space-x-2">
              <div class="mt-2 flex-grow">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Email</label>
                <input type="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                  placeholder="Email" v-model="ticket.email" @focus="handleFocus('email')"
                  @change="handleBlur('email')" />
              </div>
              <div class="shrink-0 mt-8">
                <input type="checkbox" id="notifyCheckbox"
                  class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded"
                  v-model="notifyCheckboxModel" />
                <label for="notifyCheckbox" class=" ml-1 text-xs font-medium text-gray-700 dark:text-gray-100">
                  Notify
                </label>
              </div>
            </div>
            <div class="flex space-x-2">
              <div class="mt-2 flex-grow">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Phone Number</label>
                <input type="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                  placeholder="xxxxxxxxxx" v-model="ticket.phone" @focus="handleFocus('phone')"
                  @change="handleBlur('phone')" />
              </div>
              <div class="shrink-0 mt-8">
                <input type="checkbox" id="publishCheckbox"
                  class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded"
                  v-model="unPublishedCheckboxModel" />
                <label for="publishCheckbox" class=" ml-1 text-xs font-medium text-gray-700 dark:text-gray-100">
                  DO NOT Publish
                </label>
              </div>
            </div>
            <ConfirmationModal :isVisible="showConfirmationModal" :field="fieldToConfirm" :value="valueToConfirm"
                  @confirm="handleUpdateUserConfirm" @cancel="handleUpdateUserCancel" />

            <!-- Description -->
            <div class="md:col-span-2 relative">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Description</label>
              <textarea v-model="ticket.description" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"></textarea>
                  <button @click="activateTextArea('requester')"
                    class="absolute right-2 bg-gray-400 bottom-2 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900"
                    style="margin-right: 1rem">
                    [ ]
                  </button>
            </div>

            <!-- Assign To, Source -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Source</label>
              <select
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                v-model="ticket.source">
                <!-- Loop through ticket sources and create an option for each source -->
                <option v-for="source in ticketSources" :value="source.name" :key="source.name">
                  {{ source.name }}
                </option>
              </select>
            </div>
            <div class="flex space-x-2">
              <div class="flex-grow">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Assign To</label>
                <select v-model="ticket.selectedTechID" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                  <option v-for="tech in technicians" :key="tech.id" :value="tech.id">{{ tech.name }}</option>
                </select>
              </div>
              <div class="shrink-0 mt-6">
                <input type="checkbox" id="urgentCheckbox"
                  class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded"
                  v-model="urgencyCheckboxModel" />
                <label for="urgentCheckbox" class=" ml-1 text-xs font-medium text-gray-700 dark:text-gray-100">
                  Urgent
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="space-y-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

            <!-- Notes/Direction -->
            <div class="md:col-span-2 relative">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Notes/Direction (Public)</label>
              <textarea v-model="ticket.delegatornotes" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"></textarea>
              <button @click="activateTextArea('aimNotesPublic')"
                    class="absolute right-2 bg-gray-400 bottom-2 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900"
                    style="margin-right: 1rem">
                    [ ]
                  </button>
            </div>

            <!-- Private Info -->
            <div class="md:col-span-2 relative">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Private Info</label>
              <textarea v-model="ticket.privateinfo" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"></textarea>
              <button @click="activateTextArea('privateNotes')"
                    class="absolute right-2 bg-gray-400 bottom-2 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900"
                    style="margin-right: 1rem">
                    [ ]
                </button>
            </div>

            
            <!-- Time frames -->
            <div class="md:col-span-2">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">When would you like this request handled?</label>
              <select v-model="ticket.selectedTimeFrameID" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                <option v-for="type in timeFrames" :key="type.id" :value="type.id">{{ type.label }}</option>
              </select>
            </div>

            <!-- Promised -->
            <div class="md:col-span-2 mt-2">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Promised</label>
                  <input type="datetime-local"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                    v-model="ticket.promisedDateTime" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                  <div>
                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Day</label>
                    <select
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                      v-model="selectedDay">
                      <option value="-1" disabled>Select a Quick Day</option>
                      <option value="0">Today</option>
                      <option value="1">Tomorrow</option>
                      <option value="2">2 Days</option>
                      <option value="3">3 Days</option>
                      <option value="4">4 Days</option>
                      <option value="5">5 Days</option>
                      <option value="6">6 Days</option>
                      <option value="7">1 Week</option>
                      <option value="14">2 Weeks</option>
                      <option value="21">3 Weeks</option>
                      <option value="30">1 Month</option>
                      <option value="60">2 Months</option>
                      <option value="90">3 Months</option>
                    </select>
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Time</label>
                    <select
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                      v-model="selectedTime">
                      <option value="-1" disabled>Select a Quick Time</option>
                      <option value="07:00">7:00</option>
                      <option value="08:00">8:00</option>
                      <option value="09:00">9:00</option>
                      <option value="10:00">10:00</option>
                      <option value="11:00">11:00</option>
                      <option value="12:00">12:00</option>
                      <option value="13:00">1:00</option>
                      <option value="14:00">2:00</option>
                      <option value="15:00">3:00</option>
                      <option value="16:00">4:00</option>
                      <option value="17:00">5:00</option>
                      <option value="18:00">6:00</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <!-- Deadline -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Deadline</label>
              <input type="datetime-local" v-model="ticket.deadlineDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
            </div>

            <!-- Estimated Hours -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
              <div>
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Estimated Hours</label>
                <input type="number"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                  placeholder="Extimated Hours" v-model="ticket.est_time" />
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Select Estimated hours</label>
                <select
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                  v-model="ticket.est_time">
                  <option value="0.1">0.1</option>
                  <option value="0.25">0.25</option>
                  <option value="0.50">0.50</option>
                  <option value="0.75">0.75</option>
                  <option value="1.00">1.00</option>
                  <option value="1.50">1.50</option>
                  <option value="2.00">2.00</option>
                  <option value="3.00">3.00</option>
                  <option value="4.00">4.00</option>
                </select>
              </div>
            </div>

            <!-- Attach Documents -->
            <div class="md:col-span-2 flex items-center">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Attach Document:</label>
              <input type="file" @change="handleFileUpload" ref="fileInput"
                class="ml-2 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100" />
            </div>
            <!-- Display of uploaded documents -->
            <div class="md:col-span-2 mt-4" v-if="ticket.docs && ticket.docs.length > 0">
              <div
                class="p-4 bg-white shadow-md rounded-md border-2 border-blue-900 relative overflow-x-auto dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                <div
                  class="absolute top-0 left-0 right-0 bg-blue-900 px-3 py-1 flex items-center dark:bg-gray-800 w-full">
                  <div class="flex items-center space-x-2">
                    <h4 class="text-white font-bold text-xs">
                      Attached Documents
                    </h4>
                  </div>
                </div>
                <div>
                  <table class="min-w-full divide-y divide-gray-200 mt-4">
                    <thead>
                      <tr>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Name
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="doc in ticket.docs" :key="doc.id"
                        class="bg-white dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          {{ doc.doc_name }}
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs font-medium">
                          <button @click="deleteDocument(doc)" class="text-red-600 hover:text-red-900">Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- Reminder -->
            <div class="md:col-span-2">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Set Reminder</label>
                  <input type="checkbox" v-model="reminderCheckboxModel" class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded">
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Set Reminder for Me Instead of Selected Tech</label>
                  <input type="checkbox" v-model="remindMeCheckboxModel" class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded">
                </div>
              </div>
              <div class="md:col-span-2" v-if="setReminder">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                  <div>
                    <input type="datetime-local"
                      class="mt-5 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                      v-model="ticket.reminderDateTime" />
                  </div>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                    <div>
                      <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Day</label>
                      <select
                        class="block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                        v-model="selectedReminderDay">
                        <option value="-1" disabled>Select a Quick Day</option>
                        <option value="0">Today</option>
                        <option value="1">Tomorrow</option>
                        <option value="2">2 Days</option>
                        <option value="3">3 Days</option>
                        <option value="4">4 Days</option>
                        <option value="5">5 Days</option>
                        <option value="6">6 Days</option>
                        <option value="7">1 Week</option>
                        <option value="14">2 Weeks</option>
                        <option value="21">3 Weeks</option>
                        <option value="30">1 Month</option>
                        <option value="60">2 Months</option>
                        <option value="90">3 Months</option>
                      </select>
                    </div>
                    <div>
                      <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Time</label>
                      <select
                        class="block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                        v-model="selectedReminderTime">
                        <option value="-1" disabled>Select a Quick Time</option>
                        <option value="07:00">7:00</option>
                        <option value="08:00">8:00</option>
                        <option value="09:00">9:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">1:00</option>
                        <option value="14:00">2:00</option>
                        <option value="15:00">3:00</option>
                        <option value="16:00">4:00</option>
                        <option value="17:00">5:00</option>
                        <option value="18:00">6:00</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <textarea v-model="ticket.reminder_desc" placeholder="Description" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100" v-if="setReminder"></textarea>
            </div>

            <!-- Sub Ticket
            <div class="md:col-span-2 mt-4">
              <div
                class="p-4 bg-white shadow-md rounded-md border-2 border-blue-900 relative overflow-x-auto dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                <div
                  class="absolute top-0 left-0 right-0 bg-blue-900 px-3 py-1 flex items-center dark:bg-gray-800 w-full">
                  <div>
                    <div class="flex items-center space-x-2">
                      <h4 class="text-white font-bold text-xs">
                        Add Sub Ticket
                      </h4>
                      <button @click="addSubTicket"
                        class="mt-1 mb-1 bg-gray-400 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-blue-900 dark:bg-blue-900">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <table class="min-w-full divide-y divide-gray-200 mt-8">
                    <thead>
                      <tr>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Sub Ticket Description
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Promised Date
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Estimated Hours
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(subTicket, index) in ticket.subTickets" :key="subTicket.id" class="bg-white dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          <input v-model="subTicket.description" placeholder="Description" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"/>
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          <input type="datetime-local" v-model="subTicket.promisedDate"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                          />
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          <input type="number" v-model="subTicket.estimatedHours"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                            placeholder="Extimated Hours"
                          />
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs font-medium">
                          <a href="#" @click="deleteSubTicket(index)" class="text-red-600 hover:text-red-900">Delete</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>
      <!-- Submit Button -->
      <div class="md:col-span-2 text-center">
        <button type="submit" @click="submitTicket" class="mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">Add Ticket</button>
      </div>
    <!-- Large Textarea Modal -->
    <LargeTextareaModal :isVisible="showTextArea" :initialText="getTextareaInitialText()"
              :activeTextarea="activeTextarea" @close="showTextArea = false" @complete="handleTextCompletion" />
  </div>
</template>
  
<script>
import axios from 'axios';
import LargeTextareaModal from "@/views/modals/LargeTextareaModal.vue";
import { computed, watch, ref } from 'vue';
import AddRequesterModal from "@/views/modals/AddRequesterModal.vue";
import RequesterConfirmationModal from "@/views/modals/RequesterConfirmationModal.vue";
import ConfirmationModal from "@/views/modals/ConfirmationModal.vue";

const fileInput = ref('');

export default {
  components: {
    LargeTextareaModal,
    AddRequesterModal,
    RequesterConfirmationModal,
    ConfirmationModal
  },
  data() {
    return {
      ticket: {
        companyname: '',
        submissionDateTime: '',
        company_id: '',
        contact_id: '',
        requester: '',
        phone: '',
        email: '',
        description: '',
        tickettype: '',
        tickettypeid: '',
        selectedTechID: '',
        selectedTimeFrameID: '',
        delegatornotes: '',
        privateinfo: '',
        promisedDateTime: '',
        deadlineDate: '',
        est_time: 0,
        urgent: 0,
        unpublished: 0,
        reminderDateTime: '',
        reminder_desc: '',
        oldRequester: '',
        watchersList: [],
        emailnotification: 1,
        source: '',
        subTickets: [],
        setMyReminder: 0
      },
      activeClients: 'true',
      showWatcher: false,
      showWatcherDropdown: false,
      showReqDropdown: false,
      showAddUserModal:  false,
      showReqConfirmationModal: false,
      showConfirmationModal: false,
      showTextArea: false,
      activeTextarea: '',
      watchersInput: '',
      selectedRequester: '',
      fieldToConfirm: '',
      valueToConfirm: '',
      clients: [],
      requesters: [],
      allRequesters: [],
      ticketTypes: [],
      timeFrames: [],
      technicians: [],
      ticketSources: [],
      setReminder: 0,
      selectedDay: '-1',
      selectedTime: '-1',
      selectedReminderTime: '-1',
      selectedReminderDay: '-1',
      showClientDropdown: false,
      showReqTypeDropdown: false,
      timeframesMapping: {
        '1': this.getCurrentLocalTime(), // Immediately (current local time)
        '2': { days: 0, time: '12:00' }, // By noon today
        '3': { days: 0, time: '17:00' }, // By end of day
        '8': { days: 1, time: '12:00' }, // Noon Tomorrow (next business day)
        '4': { days: 1, time: '17:00' }, // Tomorrow (next business day)
        '5': this.getEndOfWeekFriday(), // By end of week (upcoming Friday 5 PM)
        '6': this.getEndOfNextWeekFriday(), // By end of next week (following Friday 5 PM)
        '7': { days: 0, time: '17:00' }, // No rush, whenever you can get to it
      }
    };
  },
  methods: {
    async fetchInitialData() {
      this.$updateLoading(1);
      try {
        await this.fetchClients();
        
        // Get all requesters
        const requestersResponse = await axios.get(`requesters/`);
        this.requesters = requestersResponse.data;

        // Get active techs
        const techniciansResponse = await axios.get(`user/active-users`);
        this.technicians = techniciansResponse.data;

        // Get ticket sources
        const ticketSourcesResponse = await axios.get(`ticket/sources`);
        this.ticketSources = ticketSourcesResponse.data;

        // Get ticket time frames
        const timeFramesResponse = await axios.get(`ticket/timeframes`);
        this.timeFrames = timeFramesResponse.data;
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        this.$updateLoading(-1);
        console.log('loading stopped');
      }
    },
    async fetchClients() {
      console.log('SIGh;aodirfgjh;oaigrj',this.activeClients)
      try {
        const clientsResponse = await axios.get(this.activeClients == 'true' ? 'clients/' : 'clients/deactived/');
        this.clients = clientsResponse.data;
      } catch (error) {
        console.error("There was an error fetching clients:", error);
      }
    },
    handleFileUpload (event) {
      const file = event.target.files[0];
      const newDocument = {
        id: Date.now(), // Mock ID, using timestamp to simulate uniqueness
        doc_name: file.name,
        file: file
      };

      // Directly push the new document into the currentTicket's documents array
      if (this.ticket.docs) {
        this.ticket.docs.push(newDocument);
      } else {
        // Initialize the documents array if it doesn't exist
        this.ticket = { ...this.ticket, docs: [newDocument] };
      }
      if (fileInput.value) {
        fileInput.value.value = ''; // Resets the file input element
      }
    },
    setClient (client) {
      this.ticket.company_id = client.id;
      this.ticket.companyname = client.companyname;
      // searchReqTypeQuery.value = type.description;
      this.showClientDropdown = false;
    },
    setReqType (type) {
      this.ticket.tickettypeid = type.id;
      this.ticket.tickettype = type.description;
      // searchReqTypeQuery.value = type.description;
      this.showReqTypeDropdown = false;
    },
    closeClientDropdown () {
      this.showClientDropdown = false;
    },
    closeTypeDropdown () {
      this.showReqTypeDropdown = false;
    },
    closeReqDropdown () {
      this.showReqDropdown = false;
    },
    closeWatcherDropdown () {
      this.showWatcherDropdown = false;
    },
    toggleWatcherDropdown () {
      this.showWatcherDropdown = !this.showWatcherDropdown;
    },
    setReq(req) {
      this.ticket.requester = req.name;
      this.ticket.contact_id = req.id;
      this.ticket.email = req.email;
      // searchReqTypeQuery.value = type.description;
      this.showReqDropdown = false;
      this.ticket.phone = req.work_phone ? req.work_phone : req.cell_phone ? req.cell_phone : ''; // Reset phone
    },
    confirmRequesterChange(req) {
      if (this.ticket.contact_id && this.ticket.contact_id != req.id) {
        this.selectedRequester = req;
        console.log('1');
        // Find the old requester by contact_id
        const oldRequester = this.requesters.find(r => r.id === this.ticket.contact_id);
        if (oldRequester) {
          this.ticket.oldRequester = { id: oldRequester.id, name: oldRequester.name, visible: true };
        } else {
          this.ticket.oldRequester = { id: this.ticket.contact_id, name: this.ticket.requester, visible: true };
        }
        this.showReqConfirmationModal = true;
      } else {
        console.log('2');
        this.setReq(req);
      }
    },
    handleReqConfirmation (addOldRequesterAsWatcher){
      if (addOldRequesterAsWatcher) {
        this.ticket.watchersList.push(this.ticket.oldRequester);
      }
      this.setReq(this.selectedRequester);
      console.log(this.ticket.watchersList)
      this.showReqConfirmationModal = false;
    },
    toggleWatcher () {
      this.showWatcher = !this.showWatcher;
    },
    addWatcher (watcher) {
      if (!this.ticket.watchersList.some(w => w.id === watcher.id)) {
        this.ticket.watchersList.push({ id: watcher.id, name: watcher.name, visible: true });
      }
      this.showWatcherDropdown = false;
      this.watchersInput = '';
    },
    removeWatcher (watcher) {
      const watcherIndex = this.ticket.watchersList.findIndex(w => w.id === watcher.id);
      if (watcherIndex !== -1) {
        this.ticket.watchersList[watcherIndex].visible = false;
      }
    },
    handleFocus (field) {
      this.ticket['old'+field]=this.ticket[field];
      console.log(this.ticket['old'+field])
      this.showConfirmationModal = false;
    },
    handleBlur (field) {
      if (this.ticket[field]) {
        this.fieldToConfirm = field;
        this.valueToConfirm = this.ticket[field];
        this.showConfirmationModal = true;
      }
    },
    handleUpdateUserConfirm (data) {
      // Logic to handle the confirmation action
      this.ticket[data.field]=this.ticket[data.field]
      console.log('return to handleUpdateUserConfirm, make a post api call to update');
      this.showConfirmationModal = false;
    },
    handleUpdateUserCancel (data) {
      // Logic to handle the cancellation action
      console.log(data.field)
      this.ticket[data.field]=this.ticket['old'+data.field]
      this.showConfirmationModal = false;
    },
    handleAddUserCompletion ({ newUser }) {
      console.log(newUser.email)
      this.ticket.requester = newUser.firstName + ' ' + newUser.lastName;
      this.ticket.email = newUser.email;
      this.ticket.phone = newUser.phone;
      this.ticket.contact_id = 0;
      // call the api that adds the user
      // call the api to get updated list of requesters
      // Close the modal and reset the active textarea identifier
      this.showAddUserModal = false;
    },
    activateTextArea (key) {
      this.activeTextarea = key;
      this.showTextArea = true;
    },
    getTextareaInitialText () {
      switch (this.activeTextarea) {
        case "requester":
          return this.ticket.description;
        case "aimNotesPublic":
          return this.ticket.delegatornotes;
        case "privateNotes":
          return this.ticket.privateinfo;
        default:
          return "";
      }
    },
    handleTextCompletion ({ updatedText, key }) {
      const fieldMapping = {
        requester: 'description',
        aimNotesPublic: 'delegatornotes',
        privateNotes: 'privateinfo'
      };
      const dbField = fieldMapping[key]; // Get the database field name from the mapping
      const oldText = this.ticket[dbField]; // Use the correct field name to get the old value

      // Check if the text actually changed before making an API call
      if (updatedText !== oldText) {
        this.ticket[dbField] = updatedText; // Update the value in the current ticket
        // this.changedNotes(dbField); // Call the helper function to update the database
      }

      // Close the modal and reset the active textarea identifier
      this.showTextArea = false;
      this.activeTextarea = null;
    },
    getCurrentLocalTime() {
      const now = new Date();
      return { days: 0, time: now.toTimeString().slice(0, 5) }; // HH:MM format of current time
    },
    getEndOfWeekFriday() {
      const today = new Date();
      const currentDay = today.getDay();
      const daysUntilFriday = (5 - currentDay + 7) % 7; // Calculate days until next Friday
      return { days: daysUntilFriday, time: '17:00' };
    },
    getEndOfNextWeekFriday() {
      const today = new Date();
      const currentDay = today.getDay();
      const daysUntilNextFriday = ((5 - currentDay + 7) % 7) + 7; // Calculate days until the Friday after next
      return { days: daysUntilNextFriday, time: '17:00' };
    },
    updatePromisedTime() {
      const today = new Date();
      const dayOffset = this.selectedDay === '30' ? 30
        : this.selectedDay === '60' ? 60
          : this.selectedDay === '90' ? 90
            : parseInt(this.selectedDay);
      today.setDate(today.getDate() + dayOffset);

      // Check if the month or day needs a leading zero
      const monthStr = (today.getMonth() + 1).toString().padStart(2, '0');
      const dayStr = today.getDate().toString().padStart(2, '0');

      const dateStr = `${today.getFullYear()}-${monthStr}-${dayStr}`; // Format: YYYY-MM-DD
      const timeStr = this.selectedTime; // Time as HH:MM

      this.ticket.promisedDateTime = `${dateStr}T${timeStr}`;
    },
    updateReminderPromisedTime() {
      const today = new Date();
      const dayOffset = this.selectedReminderDay === '30' ? 30
        : this.selectedReminderDay === '60' ? 60
          : this.selectedReminderDay === '90' ? 90
            : parseInt(this.selectedReminderDay);
      today.setDate(today.getDate() + dayOffset);

      // Check if the month or day needs a leading zero
      const monthStr = (today.getMonth() + 1).toString().padStart(2, '0');
      const dayStr = today.getDate().toString().padStart(2, '0');

      const dateStr = `${today.getFullYear()}-${monthStr}-${dayStr}`; // Format: YYYY-MM-DD
      const timeStr = this.selectedReminderTime; // Time as HH:MM

      this.ticket.reminderDateTime = `${dateStr}T${timeStr}`;
    },
    setPromisedDateTime(daysOffset, time) {
      const promisedDate = new Date();
      promisedDate.setDate(promisedDate.getDate() + daysOffset);

      // Convert to local timezone
      const [hours, minutes] = time.split(':');
      promisedDate.setHours(hours, minutes, 0, 0);

      // Adjust to user's local timezone
      const localOffset = promisedDate.getTimezoneOffset() * 60000;
      const localTime = new Date(promisedDate.getTime() - localOffset);
      const localISOTime = localTime.toISOString().slice(0, 16);

      this.ticket.promisedDateTime = localISOTime;
    },
    deleteDocument (document) {
      if (this.ticket.docs) {
        this.ticket.docs = this.ticket.docs.filter(doc => doc.id !== document.id);
      }
    },
    addSubTicket () {
      this.ticket.subTickets.push({
        id: Date.now(),
        description: '',
        promisedDate: '',
        estimatedHours: ''
      });
    },
    deleteSubTicket (index) {
      this.ticket.subTickets.splice(index, 1);
    },
    async submitTicket() {
      this.$updateLoading(1);
      try {
        const formData = new FormData();
        for (const key in this.ticket) {
          if (key !== 'docs' || key!='watchersList' || key!='subTickets') {
            formData.append(key, this.ticket[key]);
          }
          if (key === 'docs') {
            for (const doc of this.ticket.docs) {
              formData.append('docs[]', doc.file);
            }
          }
          if (key === 'watchersList') {
            this.ticket.watchersList.forEach(watcher => {
              formData.append('watchers[]', JSON.stringify({ id: watcher.id, visible: watcher.visible }));
            });
          }
          if (key === 'subTickets') {
              this.ticket.subTickets.forEach(subTicket => {
              formData.append('subTickets[]', JSON.stringify({ description: subTicket.description, promisedDate: subTicket.promisedDate, estimatedHours: subTicket.estimatedHours }));
            });
          }
        }
        const response = await axios.post('ticket/add', formData);
        alert('Ticket added successfully');
        this.resetForm();
        const now = new Date();
        // Adjust for local time zone
        this.ticket.submissionDateTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
        this.fetchInitialData();
      } catch (error) {
        console.error('Error adding ticket:', error);
        alert('Failed to add ticket');
      }
      this.$updateLoading(-1);
    },
    resetForm() {
      this.ticket = {
        submissionDateTime: '',
        company_id: '',
        contact_id: '',
        requester: '',
        phone: '',
        email: '',
        description: '',
        tickettypeid: '',
        tickettype: '',
        companyname: '',
        selectedTechID: '',
        selectedTimeFrameID: '',
        delegatornotes: '',
        privateinfo: '',
        promisedDateTime: '',
        deadlineDate: '',
        est_time: 0,
        urgent: 0,
        unpublished: 0,
        reminderDateTime: '',
        reminder_desc: '',
        oldRequester: '',
        watchersList: [],
        emailnotification: 1,
        source: '',
        subTickets: [],
        setMyReminder: 0
      };
      this.activeClients = 'true';
      this.showWatcher = false;
      this.showWatcherDropdown = false;
      this.showReqDropdown = false;
      this.showAddUserModal = false;
      this.showReqConfirmationModal = false;
      this.showConfirmationModal = false;
      this.showTextArea = false;
      this.activeTextarea = '';
      this.watchersInput = '';
      this.selectedRequester = '';
      this.fieldToConfirm = '';
      this.valueToConfirm = '';
      this.clients = [];
      this.requesters = [];
      this.allRequesters = [];
      this.ticketTypes = [];
      this.timeFrames = [];
      this.technicians = [];
      this.ticketSources = [];
      this.setReminder = 0;
      this.selectedDay = '-1';
      this.selectedTime = '-1';
      this.selectedReminderTime = '-1';
      this.selectedReminderDay = '-1';
      this.showClientDropdown = false;
      this.showReqTypeDropdown = false;
    }
  },
  mounted() {
    const now = new Date();
    // Adjust for local time zone
    this.ticket.submissionDateTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
    this.fetchInitialData();
  },
  computed: {
    filteredClients () {
      return this.ticket.companyname
        ? this.clients.filter((e) =>
          e.companyname
            .toLowerCase()
            .includes(this.ticket.companyname.toLowerCase())
        )
        : this.clients;
    },
    filteredReqTypes() {
      return this.ticket.tickettype
        ? this.ticketTypes.filter((e) =>
          e.description
            .toLowerCase()
            .includes(this.ticket.tickettype.toLowerCase())
        )
        : this.ticketTypes;
    },
    filteredRequesters() {
      return this.ticket.requester
        ? this.requesters.filter((e) =>
            e.name.toLowerCase().includes(this.ticket.requester.toLowerCase())
          ).slice(0, 20)
        : this.allRequesters;
    },
    filteredWatchers () {
      // Filter requesters based on the watcher input
      return this.watchersInput
        ? this.requesters.filter((e) =>
          e.name
            .toLowerCase()
            .includes(this.watchersInput.toLowerCase())
        ).slice(0, 20)
        : this.allRequesters;
    },
    notifyCheckboxModel: {
      get() {
        return this.ticket.emailnotification === 1;
      },
      set(newValue) {
        this.ticket.emailnotification = newValue ? 1 : 0;
      }
    },
    unPublishedCheckboxModel: {
      get() {
        return this.ticket.unpublished === 1;
      },
      set(newValue) {
        this.ticket.unpublished = newValue ? 1 : 0;
      }
    },
    urgencyCheckboxModel: {
      get() {
        return this.ticket.urgent === 1;
      },
      set(newValue) {
        this.ticket.urgent = newValue ? 1 : 0;
      }
    },
    reminderCheckboxModel: {
      get() {
        return this.setReminder === 1;
      },
      set(newValue) {
        this.setReminder = newValue ? 1 : 0;
      }
    },
    remindMeCheckboxModel: {
      get() {
        return this.ticket.setMyReminder === 1;
      },
      set(newValue) {
        this.ticket.setMyReminder = newValue ? 1 : 0;
      }
    }
  },
  watch: {
    'ticket.company_id': function(newVal, oldVal) {
      axios.get(`requesters/${newVal}`)
      .then((response) => {
        this.allRequesters = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching requesters:", error);
      });

      axios.get(`ticket/get-types/${newVal}`)
      .then((response) => {
        this.ticketTypes = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching ticket types:", error);
      });
    },
    selectedDay(newVal, oldVal) {
      if (newVal !== '-1' && this.selectedTime !== '-1') {
        this.updatePromisedTime();
      }
    },
    selectedTime(newVal, oldVal) {
      if (newVal !== '-1' && this.selectedDay !== '-1') {
        this.updatePromisedTime();
      }
    },
    selectedReminderDay(newVal, oldVal) {
      if (newVal !== '-1' && this.selectedReminderTime !== '-1') {
        this.updateReminderPromisedTime();
      }
    },
    selectedReminderTime(newVal, oldVal) {
      if (newVal !== '-1' && this.selectedReminderDay !== '-1') {
        this.updateReminderPromisedTime();
      }
    },
    'ticket.selectedTimeFrameID': function(newVal) {
      if (newVal && this.timeframesMapping[newVal]) {
        const { days, time } = this.timeframesMapping[newVal];
        this.setPromisedDateTime(days, time);
      }
    },
  }
};
</script>
  
<style scoped>
.scrollable-requester-list {
max-height: 400px;
/* Adjust this value based on your design needs */
overflow-y: auto;
/* Enables vertical scrolling */
border: 1px solid #ccc;
/* Optional: adds a border around the list */
}
</style>
  