<template>
  <div class="flex flex-col min-h-screen bg-gray-150 p-2 dark:bg-gray-700">
    <!-- Pagination -->
    <div class="mb-3 flex justify-center" v-if="totalTickets.length > 0">
      <button @click="changePage(currentPage - 1)"
        class="mx-1 px-4 py-2 text-xs font-medium text-gray-700 bg-white rounded-lg border border-gray-300 hover:bg-gray-50 disabled:opacity-50"
        :disabled="currentPage <= 1">
        &lt;
      </button>
      <button
        class="mx-1 px-4 py-2 text-xs font-medium text-gray-700 bg-white rounded-lg border border-blue-500 hover:bg-gray-50">
        {{ currentPage }}
      </button>
      <button @click="changePage(currentPage + 1)" v-if="currentPage < totalPages"
        class="mx-1 px-4 py-2 text-xs font-medium text-gray-700 bg-white rounded-lg border border-gray-300 hover:bg-gray-50">
        {{ currentPage + 1 }}
      </button>

      <button @click="changePage(currentPage + 1)"
        class="mx-1 px-4 py-2 text-xs font-medium text-gray-700 bg-white rounded-lg border border-gray-300 hover:bg-gray-50 disabled:opacity-50"
        :disabled="currentPage >= totalPages">
        &gt;
      </button>
    </div>
    <div v-else>
      <label class="block text-l font-medium text-gray-700 text-center dark:text-gray-100 mt-4 mb-4">No undelegated tickets at this time</label>
    </div>
    <div class="flex flex-col md:flex-row gap-2" :class="totalTickets.length > 0
            ? 'md:grid md:grid-cols-[3fr,3fr,1fr]'
            : 'flex flex-col md:flex-row gap-6'
          ">
      <!-- Ticket Details Form -->
      <div class="flex-1 border rounded-2xl px-6 py-6 bg-white rounded dark:bg-gray-600 dark:border-gray-600"
        v-if="currentTicket">
        <div class="space-y-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
            <!-- ... other fields from the form in the picture ... -->
            <div class="text-right md:col-span-2">
              <button type="submit"
                class="inline-flex items-center mr-4 px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-700"
                @click="delegateTicket(currentTicket, false)">
                Delegate
              </button>
              <button type="submit"
                class="inline-flex mr-4 items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 dark:bg-green-300 dark:hover:bg-green-700"
                @click="delegateTicket(currentTicket, true)">
                Start
              </button>
              <button type="submit"
                class="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-700 dark:bg-red-300 dark:hover:bg-red-600"
                @click="deleteTicket(currentTicket.id)">
                Delete
              </button>
            </div>
            <div class="md:col-span-2" v-click-outside="closeTypeDropdown">
              <label class="block font-medium text-gray-700 text-xs dark:text-gray-100">Type</label>
              <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                placeholder="Type" v-model="currentTicket.type" @focus="showReqTypeDropdown = true" />
              <ul v-if="showReqTypeDropdown"
                class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                <li v-for="reqtype in filteredReqTypes" :key="reqtype.id" @click="setReqType(reqtype)"
                  class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                  {{ reqtype.description }}
                </li>
              </ul>
            </div>
            <div class="md:col-span-2" v-click-outside="closeClientDropdown">
              <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Client</label>
              <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                placeholder="Client Name" v-model="currentTicket.client.companyname"
                @focus="showClientDropdown = true" />
              <ul v-if="showClientDropdown"
                class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                <li v-for="client in filteredClients" :key="client.id" @click="setClient(client)"
                  class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                  {{ client.companyname }}
                </li>
              </ul>
            </div>
            <!-- <hr class="md:col-span-2 mt-4 border-t-2 border-gray-300"> -->
            <!-- ... other fields from the form in the picture ... -->
            <div class="md:col-span-2 mt-2">
              <div
                class="p-4 bg-white shadow-md rounded-md border-2 border-blue-900 relative dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                <div class="absolute top-0 left-0 right-0 bg-blue-900 px-3 py-1 flex items-center dark:bg-gray-800">
                  <div class="flex items-center space-x-2">
                    <h4 class="text-white font-bold text-xs">Requested By</h4>
                  </div>
                </div>
                <div class="md:col-span-2 mt-2">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                    <div class="flex space-x-2">
                      <div class="flex-grow" v-click-outside="closeReqDropdown">
                        <!-- <label class="block text-sm font-medium text-gray-700">Requested By</label> -->
                        <input type="text"
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                          placeholder="Select Requester" v-model="currentTicket.requester"
                          @focus="showReqDropdown = true" />
                        <ul v-if="showReqDropdown"
                          class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                          <li v-for="req in filteredRequesters" :key="req.id" @click="confirmRequesterChange(req)"
                            class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                            {{ req.name }}
                          </li>
                        </ul>
                      </div>
                      <div class="shrink-0">
                        <button @click="showAddUserModal = true"
                          class="mt-2 bg-gray-400 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900">
                          <!-- Plus icon -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <RequesterConfirmationModal v-if="showReqConfirmationModal"
                      @confirm="handleReqConfirmation(true)"
                      @cancel="handleReqConfirmation(false)" />
                    <div>
                      <!-- <label class="block text-sm font-medium text-gray-700">Requested By</label> -->
                      <select
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900"
                        v-model="currentTicket.clientLocation">
                        <option selected>Set Location</option>
                        <option v-for="address in currentTicket.additionalAddresses" :key="address.id"
                          :value="address.id">
                          {{ address.name ? address.name : address.address }}
                        </option>
                      </select>
                    </div>
                    <!-- <div v-click-outside="closeIntReqDropdown">
                      <input
                        type="text"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                        placeholder="Select Internal Requester"
                        v-model="currentTicket.requesterInt"
                        @focus="showIntReqDropdown = true"
                      />
                      <ul
                        v-if="showIntReqDropdown"
                        class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list"
                      >
                        <li
                          v-for="req in filteredIntRequesters"
                          :key="req.id"
                          @click="setIntReq(req)"
                          class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800"
                        >
                          {{ req.name }}
                        </li>
                      </ul>
                    </div> -->
                  </div>
                </div>
                <div class="md:col-span-2 mt-2">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                    <div>
                      <div class="flex">
                        <label class="block font-medium text-gray-700 text-xs dark:text-gray-100 mt-2 mr-2">Add a
                          Watcher?</label>
                        <button @click="toggleWatcher"
                          class="mt-1 bg-gray-400 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900 transition-transform duration-300"
                          :class="{ 'transform rotate-180': showWatcher }">
                          <svg
                            :class="{ 'fill-current text-gray-100': !showWatcher, 'fill-current text-blue-500': showWatcher }"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="h-4 w-4">
                            <path
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                          </svg>
                        </button>
                      </div>
                      <transition name="slide-fade">
                        <div v-click-outside="closeWatcherDropdown">
                          <input v-if="showWatcher" type="email"
                            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100 transition-all duration-300 ease-in-out"
                            placeholder="Enter Requester Email if not in System" @focus="toggleWatcherDropdown"
                            v-model="watchersInput"/>
                          <ul v-if="showWatcherDropdown"
                            class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                            <li v-for="watcher in filteredWatchers" :key="watcher.id" @click="addWatcher(watcher)"
                              class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                              {{ watcher.name }}
                            </li>
                          </ul>
                          <div class="mt-1 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">
                            <div v-for="watcher in currentTicket.watchers.filter(w => w.visible)" :key="watcher.id"
                              class="flex justify-between items-center border border-gray-200 rounded-full p-1 text-xs dark:border-gray-900 dark:bg-gray-400">
                              {{ watcher.name }}
                              <button @click="removeWatcher(watcher)"
                                class="ml-2 bg-red-600 hover:bg-red-700 text-white p-1 rounded-full text-xs">
                                &times;
                              </button>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                    <!-- <div>
                      <div class="flex">
                        <label class="block font-medium text-gray-700 text-xs dark:text-gray-100 mt-2 mr-2">Add a
                          Assignee?</label>
                        <button @click="toggleRequesterAssignee"
                          class="mt-1 bg-gray-400 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900 transition-transform duration-300"
                          :class="{ 'transform rotate-180': showRequestAssignee }">
                          <svg
                            :class="{ 'fill-current text-gray-100': !showRequestAssignee, 'fill-current text-blue-500': showRequestAssignee }"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="h-4 w-4">
                            <path
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                          </svg>
                        </button>
                      </div>
                      <transition name="slide-fade">
                        <div v-click-outside="closeReqAssigneeDropdown">
                          <input v-if="showRequestAssignee" type="email"
                            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100 transition-all duration-300 ease-in-out"
                            placeholder="Enter Requester Email if not in System" @focus="toggleRequestAssigneeDropdown"
                            v-model="reqAssigneesInput"/>
                          <ul v-if="showRequestAssigneeDropdown"
                            class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                            <li v-for="assignee in filteredRequestAssignees" :key="assignee.id"
                              @click="addRequestAssignee(assignee)"
                              class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                              {{ assignee.name }}
                            </li>
                          </ul>
                          <div class="mt-1 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">
                            <div v-for="assignee in currentTicket.reqAssignees" :key="assignee.id"
                              class="flex justify-between items-center border border-gray-200 rounded-full p-1 text-xs dark:border-gray-900 dark:bg-gray-400">
                              {{ assignee.name }}
                              <button @click="removeRequestAssignee(assignee)" class="ml-2 bg-red-600 hover:bg-red-700 text-white p-1 rounded-full text-xs">
                                &times;
                              </button>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <AddRequesterModal :isVisible="showAddUserModal" @close="showAddUserModal = false"
              @complete="handleAddUserCompletion" />
            <div class="flex space-x-2">
              <div class="mt-2 flex-grow">
                <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Email</label>
                <input type="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                  placeholder="Email" v-model="currentTicket.email" @focus="handleFocus('email')"
                  @change="handleBlur('email')" />
              </div>
              <div class="shrink-0 mt-8">
                <input type="checkbox" id="notifyCheckbox"
                  class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded"
                  v-model="notifyCheckboxModel" />
                <label for="notifyCheckbox" class=" ml-1 text-xs font-medium text-gray-700 dark:text-gray-100">
                  Notify
                </label>
              </div>
            </div>
            <div class="flex space-x-2">
              <div class="mt-2 flex-grow">
                <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Phone Number</label>
                <input type="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                  placeholder="xxxxxxxxxx" v-model="currentTicket.alternatephone" @focus="handleFocus('alternatephone')"
                  @change="handleBlur('alternatephone')" />
              </div>
              <div class="shrink-0 mt-8">
                <input type="checkbox" id="publishCheckbox"
                  class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded"
                  v-model="unPublishedCheckboxModel" />
                <label for="publishCheckbox" class=" ml-1 text-xs font-medium text-gray-700 dark:text-gray-100">
                  DO NOT Publish
                </label>
              </div>
            </div>

            <ConfirmationModal :isVisible="showConfirmationModal" :field="fieldToConfirm" :value="valueToConfirm"
              @confirm="handleUpdateUserConfirm" @cancel="handleUpdateUserCancel" />

            <div class="mt-2">
              <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Source</label>
              <select
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800"
                v-model="currentTicket.source">
                <!-- Loop through ticket sources and create an option for each source -->
                <option v-for="source in ticketSources" :value="source.name" :key="source.name">
                  {{ source.name }}
                </option>
              </select>
            </div>
            <div class="flex space-x-2">
              <div class="mt-2 flex-grow" v-click-outside="closeAssignDropdown">
                <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Assign To</label>
                <input type="text"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                  placeholder="Assign To" v-model="currentTicket.assign" @focus="showAssignDropdown = true" />
                <ul v-if="showAssignDropdown"
                  class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                  <li v-for="assignee in filteredAssignees" :key="assignee.id" @click="setAssignee(assignee)"
                    class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                    {{ assignee.name }}
                  </li>
                </ul>
              </div>
              <div class="shrink-0 mt-8">
                <input type="checkbox" id="urgentCheckbox"
                  class="form-checkbox h-4 w-4 text-blue-600 dark:text-gray-900 rounded"
                  v-model="urgencyCheckboxModel" />
                <label for="urgentCheckbox" class=" ml-1 text-xs font-medium text-gray-700 dark:text-gray-100">
                  Urgent
                </label>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
              <div class="mt-2">
                <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Estimated Hours</label>
                <input type="number"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                  placeholder="Extimated Hours" v-model="currentTicket.esttime" />
              </div>
              <div class="mt-2">
                <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Select Estimated hours</label>
                <select
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                  v-model="currentTicket.esttime">
                  <option value="0.1">0.1</option>
                  <option value="0.25">0.25</option>
                  <option value="0.50">0.50</option>
                  <option value="0.75">0.75</option>
                  <option value="1.00">1.00</option>
                  <option value="1.50">1.50</option>
                  <option value="2.00">2.00</option>
                  <option value="3.00">3.00</option>
                  <option value="4.00">4.00</option>
                </select>
              </div>
            </div>

            <div class="mt-2">
              <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Deadline</label>
              <input type="datetime-local"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                v-model="currentTicket.deadline" />
            </div>
            <div class="md:col-span-2 mt-2">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                <div>
                  <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Promised</label>
                  <input type="datetime-local"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                    v-model="currentTicket.promised" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                  <div>
                    <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Day</label>
                    <select
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                      v-model="selectedDay">
                      <option value="-1" disabled>Select a Quick Day</option>
                      <option value="0">Today</option>
                      <option value="1">Tomorrow</option>
                      <option value="2">2 Days</option>
                      <option value="3">3 Days</option>
                      <option value="4">4 Days</option>
                      <option value="5">5 Days</option>
                      <option value="6">6 Days</option>
                      <option value="7">1 Week</option>
                      <option value="14">2 Weeks</option>
                      <option value="21">3 Weeks</option>
                      <option value="30">1 Month</option>
                      <option value="60">2 Months</option>
                      <option value="90">3 Months</option>
                    </select>
                  </div>
                  <div>
                    <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Time</label>
                    <select
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                      v-model="selectedTime">
                      <option value="-1" disabled>Select a Quick Time</option>
                      <option value="07:00">7:00</option>
                      <option value="08:00">8:00</option>
                      <option value="09:00">9:00</option>
                      <option value="10:00">10:00</option>
                      <option value="11:00">11:00</option>
                      <option value="12:00">12:00</option>
                      <option value="13:00">1:00</option>
                      <option value="14:00">2:00</option>
                      <option value="15:00">3:00</option>
                      <option value="16:00">4:00</option>
                      <option value="17:00">5:00</option>
                      <option value="18:00">6:00</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="md:col-span-2 text-right mt-2">
              <button
                class="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-700"
                @click="showModal = true">
                Open related tickets
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Second Column: Notes and Related Tickets -->
      <div class="flex-1 border rounded-2xl px-6 py-6 bg-white rounded shadow dark:bg-gray-600 dark:border-gray-600"
        v-if="currentTicket">
        <div class="flex-1 border rounded-2xl px-6 py-6 bg-white rounded dark:bg-gray-600 dark:border-gray-600">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2" v-if="currentTicket">
            <!-- <div class="mt-2">
              <label
                class="block text-xs font-medium text-gray-700 dark:text-gray-100"
                >Project Related</label
              >
              <select
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800"
                v-model="currentTicket.project_id"
              >
                <option value="" selected>Please Select</option>
                <option
                  v-for="project in currentTicket.projects"
                  :key="project.id"
                  :value="project.id"
                  :selected="currentTicket.project_id == project.id"
                >
                  {{ project.created }} / {{ project.name }}
                </option>
              </select>
            </div> -->
            <!-- <div class="mt-2">
              <label
                class="block text-xs font-medium text-gray-700 dark:text-gray-100"
                >Redmine</label
              >
              <input
                type="number"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800 dark:placeholder-gray-100"
                v-model="currentTicket.redmineissueid"
                placeholder="Redmine ID"
              />
            </div> -->

            <!-- <div class="md:col-span-2 mt-2">
              <label
                class="block text-xs font-medium text-gray-700 dark:text-gray-100"
                >PC/Server</label
              >
              <select
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800"
                v-model="currentTicket.pcid"
              >
                <option selected>Select PC/Server</option>
                <option>2024-11-26</option>
              </select>
            </div> -->

            <div class="relative md:col-span-2 mt-2">
              <label for="requesterNotes" class="block text-xs font-medium text-gray-700 dark:text-gray-100">Requester
                Notes</label>
              <textarea id="requesterNotes"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                rows="2" v-model="currentTicket.description" style="padding-bottom: 2rem"
                @change="changedNotes('description')"></textarea>
              <button @click="activateTextArea('requester')"
                class="absolute right-2 bg-gray-400 bottom-2 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900"
                style="margin-right: 1rem">
                [ ]
              </button>
            </div>
            <!-- Large Textarea Modal -->
            <LargeTextareaModal :isVisible="showTextArea" :initialText="getTextareaInitialText()"
              :activeTextarea="activeTextarea" @close="showTextArea = false" @complete="handleTextCompletion" />

            <div class="relative md:col-span-2 mt-2">
              <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">AIM Notes (Public)</label>
              <textarea
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                rows="3" v-model="currentTicket.delegatornotes" @change="changedNotes('delegatornotes')"></textarea>
              <button @click="activateTextArea('aimNotesPublic')"
                class="absolute right-2 bg-gray-400 bottom-2 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900"
                style="margin-right: 1rem">
                [ ]
              </button>
            </div>

            <div class="relative md:col-span-2">
              <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Private Notes</label>
              <textarea
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 dark:placeholder-gray-100"
                rows="3" v-model="currentTicket.privateinfo" placeholder="No private info to share"
                @change="changedNotes('privateinfo')"></textarea>
              <button @click="activateTextArea('privateNotes')"
                class="absolute right-2 bg-gray-400 bottom-2 p-1 border rounded text-xs leading-none dark:text-gray-100 dark:border-gray-900 dark:bg-gray-900"
                style="margin-right: 1rem">
                [ ]
              </button>
            </div>

            <div class="md:col-span-2 flex items-center">
              <label class="block text-xs font-medium text-gray-700 dark:text-gray-100">Attach Document:</label>
              <input type="file" @change="handleFileUpload" ref="fileInput"
                class="ml-2 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100" />
              <!-- <span class="dark:text-gray-100" v-if="uploadedFile">{{
                uploadedFile.name
              }}</span> -->
            </div>
            <!-- Display of uploaded documents -->
            <div class="md:col-span-2 mt-4">
              <div
                class="p-4 bg-white shadow-md rounded-md border-2 border-blue-900 relative overflow-x-auto dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800">
                <div
                  class="absolute top-0 left-0 right-0 bg-blue-900 px-3 py-1 flex items-center dark:bg-gray-800 w-full">
                  <div class="flex items-center space-x-2">
                    <h4 class="text-white font-bold text-xs">
                      Attached Documents
                    </h4>
                  </div>
                </div>
                <div>
                  <table class="min-w-full divide-y divide-gray-200 mt-4">
                    <thead>
                      <tr>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Name
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Type
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Thumbnail
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Delete
                        </th>
                        <th class="px-1 py-1 text-left text-xs font-medium text-gray-900 uppercase">
                          Black list
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="currentTicket.documents && currentTicket.documents.length > 0" v-for="doc in currentTicket.documents" :key="doc.id"
                        class="bg-white dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800"
                        :class="doc.blacklisted ? 'opacity-50 cursor-not-allowed' : ''">
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          {{ doc.doc_name }}
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          {{ doc.mime }}
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs text-gray-900">
                          <!-- Thumbnail display logic -->
                          <!-- <img v-if="isImage(doc.mime)" :src="generateThumbnailUrl(doc.document.s3objectname)" alt="Thumbnail" class="w-12 h-12" /> -->
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs font-medium">
                          <a href="#" @click="deleteDocument(doc)" class="text-red-600 hover:text-red-900"
                            :class="{ 'pointer-events-none opacity-50': doc.blacklisted }">Delete</a>
                        </td>
                        <td class="px-1 py-3 whitespace-nowrap text-xs font-medium">
                          <a href="#" @click="blacklistDocument(doc)" class="text-red-600 hover:text-red-900">Black
                            List</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Active Jobs Table Column -->
      <div class="flex-1 border rounded-2xl px-3 py-6 bg-white rounded shadow dark:bg-gray-600 dark:border-gray-600">
        <div class="mb-1" v-if="clientNotes.warning && totalTickets.length>0">
          <h3 class="text-xs font-medium text-gray-900 dark:text-gray-100">
            Warnings/Account Notices:
          </h3>

          <textarea
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800 dark:placeholder-gray-100"
            rows="3" placeholder="Enter client notes here..." v-model="clientNotes.warning"></textarea>
        </div>
        <div class="mb-1" v-if="clientNotes.notes && totalTickets.length>0">
          <h3 class="text-xs font-medium text-gray-900 dark:text-gray-100">
            Client Notes:
          </h3>
          <textarea
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm text-xs dark:text-gray-100 dark:bg-gray-400 dark:border-gray-800 dark:placeholder-gray-100"
            rows="3" placeholder="Enter client notes here..." v-model="clientNotes.notes"></textarea>
        </div>
        <label class="block text-l font-medium text-gray-700 text-center dark:text-gray-100 mt-2">On-call: {{
          oncall.firstname }} {{ oncall.lastname }}</label>
        <hr class="mt-4 border-t-2 border-gray-300" />
        <label class="block text-m font-medium text-gray-700 text-center mt-4 dark:text-gray-100">Active Jobs</label>
        <table class="divide-y divide-gray-200 mt-4 rounded-lg overflow-hidden"
          :class="{ 'w-full': totalTickets.length == 0 }">
          <thead class="bg-gray-400 dark:bg-gray-800 dark:border-gray-600">
            <tr>
              <th scope="col" class="px-1 py-3 text-xs font-medium text-gray-50 uppercase tracking-wider"
                :class="{ 'text-left px-6': totalTickets.length == 0 }">
                Tech
              </th>
              <th scope="col" class="px-1 py-3 text-xs font-medium text-gray-50 uppercase tracking-wider"
                :class="{ 'text-left px-6': totalTickets.length == 0 }">
                Remaining hours
              </th>
              <th scope="col" class="px-1 py-3 text-xs font-medium text-gray-50 uppercase tracking-wider"
                :class="{ 'text-left px-6': totalTickets.length == 0 }">
                Started
              </th>
              <th scope="col" class="px-1 py-3 text-xs font-medium text-gray-50 uppercase tracking-wider"
                :class="{ 'text-left px-6': totalTickets.length == 0 }">
                Time
              </th>
              <th scope="col" class="px-1 py-3 text-xs font-medium text-gray-50 uppercase tracking-wider"
                :class="{ 'text-left px-6': totalTickets.length == 0 }">
                Client
              </th>
              <!-- <th
                scope="col"
                class="px-1 py-3 text-xs font-medium text-gray-50 uppercase tracking-wider"
                :class="{ 'text-left px-6': totalTickets.length == 0 }"
              >
                Ticket/RM
              </th> -->
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <template v-for="job in activeJobs" :key="job.id">
              <tr>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.name }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ (15 - job.totalWorkedTime).toFixed(2) }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.stime }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ (job.howlong - 7).toFixed(2) }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.shortname }}
                </td>
                <!-- <td
                  class="px-2 py-4 whitespace-nowrap text-xs font-medium"
                  :class="
                    ({ 'px-6': totalTickets.length == 0 },
                    jobTypeClass(job.jobtype))
                  "
                >
                  <a
                    v-if="job.ticket_id != 0"
                    :href="`https://atms.aimitservices.com/viewTickets.php?searchtext=${job.ticket_id}`"
                    target="_blank"
                    style="
                      color: #0000ee;
                      text-decoration: underline;
                      margin-right: 10px;
                    "
                    >#{{ job.ticket_id }}</a
                  >
                  <a
                    v-if="job.redmine != 0"
                    :href="`https://redmine.aimitservices.com/issues/${job.redmine}`"
                    target="_blank"
                    style="color: #0000ee; text-decoration: underline"
                    >RM{{ job.redmine }}</a
                  >
                </td> -->
              </tr>
            </template>
            <tr
              class="bg-gray-400 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-100 font-medium text-gray-50 uppercase tracking-wider text-l">
              <td colspan="6" align="center">
                <br />
                <h2>Not Clocked In</h2>
                <br />
              </td>
            </tr>
            <template v-for="job in lastJobs" :key="job.id">
              <tr>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.name }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.project }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.stime }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.howlong }}
                </td>
                <td class="px-2 py-4 whitespace-nowrap text-xs font-medium" :class="({ 'px-6': totalTickets.length == 0 },
            jobTypeClass(job.jobtype))
          ">
                  {{ job.shortname }}
                </td>
                <!-- <td
                  class="px-2 py-4 whitespace-nowrap text-xs font-medium"
                  :class="
                    ({ 'px-6': totalTickets.length == 0 },
                    jobTypeClass(job.jobtype))
                  "
                >
                  <a
                    v-if="job.ticket_id != 0"
                    :href="`https://atms.aimitservices.com/viewTickets.php?searchtext=${job.ticket_id}`"
                    target="_blank"
                    style="
                      color: #0000ee;
                      text-decoration: underline;
                      margin-right: 10px;
                    "
                    >#{{ job.ticket_id }}</a
                  >
                  <a
                    v-if="job.redmine != 0"
                    :href="`https://redmine.aimitservices.com/issues/${job.redmine}`"
                    target="_blank"
                    style="color: #0000ee; text-decoration: underline"
                    >RM{{ job.redmine }}</a
                  >
                </td> -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="showModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <TicketsModal v-show="currentStep === 1" @close="handleClose()" :clientId="currentTicket.company_id" :attachTo="currentTicket.attachTo" @handleRelatedTickets="handleRelatedTickets"/>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, reactive, computed, watch } from "vue";
import TicketsModal from "@/views/modals/TicketsModal.vue";
import LargeTextareaModal from "@/views/modals/LargeTextareaModal.vue";
import AddRequesterModal from "@/views/modals/AddRequesterModal.vue";
import ConfirmationModal from "@/views/modals/ConfirmationModal.vue";
import RequesterConfirmationModal from "@/views/modals/RequesterConfirmationModal.vue";
import axios from "axios";

const showModal = ref(false);
const showWatcher = ref(false);
const showRequestAssignee = ref(false);
const showConfirmationModal = ref(false);
const showReqConfirmationModal = ref(false);
const fieldToConfirm = ref('');
const valueToConfirm = ref('');
const currentPage = ref(1);
const currentStep = ref(1);
const selectedDay = ref('-1');
const selectedTime = ref('-1');
const ticketsPerPage = 1; // Or any number you like
const inputFocused = ref(false);
const watchersInput = ref('');
const reqAssigneesInput = ref('');

const totalTickets = ref([]);
// console.log(showModal.value);
const activeJobs = ref([]);
const jobTypeClass = (type) => {
  switch (type) {
    case "W":
      return "development"; // Light Green
    case "R":
      return "remote"; // Light Pink
    case "L":
      return "lunch"; // Light Yellow
    case "B":
      return "bench"; // Light Blue
    default:
      return ""; // Default class if needed
  }
};
const runbreak = ref(null);

const relatedTickets = ref([
  // Assuming this is the structure of your tickets
  { id: 1, description: "Ticket 1 Description", isSelected: false },
  { id: 2, description: "Ticket 2 Description", isSelected: false },
  // ... other tickets
]);

const clients = ref([]);
const intRequesters = ref([]);
const ticketSources = ref([]);
const lastJobs = ref([]);
const dupJobs = ref([]);
const employees = ref([]);
const oncall = ref([]);
const clientNotes = ref([]);
const formatDateTimeLocal = (inputString) => {
  // Expected input format "YYYY-MM-DD HH:mm:ss"
  const parts = inputString.split(/[- :]/);
  const formattedDate = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
  return formattedDate.toISOString().slice(0, 16); // Converts to "YYYY-MM-DDTHH:mm" format
};
onMounted(async () => {
  axios
    .get(`${axios.defaults.baseURL}clients`)
    .then((response) => {
      clients.value = response.data;
    })
    .catch((error) => {
      console.error("There was an error fetching clients:", error);
    });


  try {
    const requestersResponse = await axios.get(`${axios.defaults.baseURL}requesters`);
    requesters.value = requestersResponse.data;

    const ticketsResponse = await axios.get(`${axios.defaults.baseURL}ticket/new`);
    totalTickets.value = ticketsResponse.data.map(ticket => {
      if (ticket.promised) {
        ticket.promised = formatDateTimeLocal(ticket.promised);
      }
      if (ticket.deadline) {
        ticket.deadline = formatDateTimeLocal(ticket.deadline);
      }
      if(ticket.email && ticket.contact && ticket.email != ticket.contact.email){
        if (ticket.contact.email.length > 0) {
          ticket.email = ticket.contact.email;
        }
      }
      if(ticket.email && ticket.contact && ticket.alternatephone != ticket.contact.work_phone){
        if (ticket.contact.work_phone.length > 0) {
          ticket.alternatephone = ticket.contact.work_phone;
        }
      }

      if (!ticket.requester && ticket.email) {
        const normalizedTicketEmail = ticket.email.trim().toLowerCase();
        const foundRequester = requesters.value.find(requester => 
          requester.email.trim().toLowerCase() === normalizedTicketEmail
        );

        if (foundRequester) {
          ticket.requester = foundRequester.name;
        }
      }
      if (ticket.description) {
        ticket.originalDescription = ticket.description;
      }
      return ticket;
    });
  } catch (error) {
    console.error("There was an error fetching data:", error);
  }
  axios
    .get(`${axios.defaults.baseURL}user/active-users`)
    .then((response) => {
      employees.value = response.data;
    })
    .catch((error) => {
      console.error("There was an error fetching active users:", error);
    });

  axios
    .get(`${axios.defaults.baseURL}ticket/sources`)
    .then((response) => {
      ticketSources.value = response.data;
    })
    .catch((error) => {
      console.error("There was an error fetching sources:", error);
    });

  axios
    .get(`${axios.defaults.baseURL}ticket/oncall`)
    .then((response) => {
      oncall.value = response.data;
    })
    .catch((error) => {
      console.error("There was an error fetching on-call user:", error);
    });
  axios
    .get(`${axios.defaults.baseURL}job/current`)
    .then((response) => {
      dupJobs.value = response.data;

      // Separate jobs into two arrays based on stime condition
      activeJobs.value = dupJobs.value.filter((job) => job.stime != "Last Job");
      lastJobs.value = dupJobs.value.filter((job) => job.stime == "Last Job");

      // Filter lastJob array to exclude names present in notLastJob array
      lastJobs.value = lastJobs.value.filter(
        (lastJob) =>
          !activeJobs.value.some((activeJob) => activeJob.uid === lastJob.uid)
      );

      lastJobs.value = lastJobs.value.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.uid === value.uid)
      );
    })
    .catch((error) => {
      console.error("There was an error fetching current jobs:", error);
    });
});

function handleClose() {
  showModal.value = false;
  console.log(showModal.value);
}

// const updateEmail = () => {
//   if (inputFocused.value) {
//     currentTicket.value.email = event.target.value;
//     inputFocused.value = false; // Reset flag
//     currentTicket.value.requester = ""; // Reset requester
//     currentTicket.value.requesterInt = ""; // Reset internal requester
//     currentTicket.value.alternatephone = "";
//   }
// };

const requestTypes = ref([
  // { id: 1, description: "Technical Support" },
  // { id: 2, description: "Account Inquiry" },
  // { id: 3, description: "Product Feedback" },
  // { id: 4, description: "Billing Question" },
  // { id: 5, description: "New Feature Request" },
  // { id: 6, description: "Data Correction" },
  // { id: 7, description: "Compliance Query" },
  // { id: 8, description: "Other" },
]);

// Computed properties to handle current ticket and pagination
const currentTicket = computed(() => {
  const index = (currentPage.value - 1) * ticketsPerPage;
  const ticket = totalTickets.value[index];

  if (!ticket) {
    console.log("totalTickets.value[index] is undefined");
    return ticket;
  }

  // Check if ticket_type exists and has a description property
  if (ticket.ticket_type && ticket.ticket_type.description) {
    ticket.type = ticket.ticket_type.description;
  } else {
    ticket.type = ""; // Set type to empty string if ticket_type does not exist or the description is empty
  }

  // Check if ticket_type exists and has a description property
  if (ticket) {
    ticket.clientLocation = "";
    ticket.attachTo = 0;
    ticket.deleteDocumentIds = [];
    ticket.extReqEmail = "";
    ticket.oldRequester= '';
    ticket.reqAssignees = [];
    ticket.watcherId = "";
    ticket.requestAssigneeId = "";
    if(!ticket.watchers && empty(ticket.watchers)) {
      ticket.watchers = [];
    }
    axios
      .get(`${axios.defaults.baseURL}projects/${ticket.company_id}`)
      .then((response) => {
        ticket.projects = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching projects related to a company:", error);
      });
    axios
      .get(`${axios.defaults.baseURL}documents/${ticket.id}`)
      .then((response) => {
        // need to change the api logic for blacklisted flag.
        const updatedDocuments = response.data.map((doc) => ({
          ...doc,
          blacklisted: doc.blacklisted !== undefined ? doc.blacklisted : false
        }));
        // Assign the updated documents with the blacklisted property
        ticket.documents = updatedDocuments;
      })
      .catch((error) => {
        console.error("There was an error fetching documents in a ticket:", error);
      });
    axios
      .get(`${axios.defaults.baseURL}ticket/get-types/${ticket.company_id}`)
      .then((response) => {
        requestTypes.value = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching ticket types:", error);
      });
    axios
      .get(`${axios.defaults.baseURL}clients/locations`, {
        params: {
          client_id: ticket.company_id,
        },
      })
      .then((response) => {
        ticket.additionalAddresses = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching locations:", error);
      });

    axios
      .get(`${axios.defaults.baseURL}clients/notes`, {
        params: {
          id: ticket.company_id,
        },
      })
      .then((response) => {
        clientNotes.value = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching notes:", error);
      });

    axios
      .get(`${axios.defaults.baseURL}requesters/${ticket.company_id}`)
      .then((response) => {
        allRequesters.value = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching requesters:", error);
      });
  } else {
    ticket.projects = ""; // Set type to empty string if ticket_type does not exist or the description is empty
    ticket.documents = "";
    ticket.requestTypes = "";
    ticket.additionalAddresses = "";
  }

  return ticket;
});

const isImage = (mimeType) => {
  // Check if the MIME type represents an image
  return mimeType.startsWith("image/");
};

const generateThumbnailUrl = (s3objectname) => {
  // Assuming the URL pattern is consistent
  return `https://atms.s3.amazonaws.com/${s3objectname}`;
};

const totalPages = computed(() => {
  return Math.ceil(totalTickets.value.length / ticketsPerPage);
});

// Methods to change pages
const changePage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const uploadedFile = ref(null);
const uploadedDocuments = ref([
  { name: "ProjectProposal.pdf", type: "PDF", id: 1 },
  { name: "ExpenseReport.xlsx", type: "Spreadsheet", id: 2 },
  { name: "DesignMockup.sketch", type: "Design", id: 3 },
  { name: "MeetingNotes.txt", type: "Text", id: 4 },
]);
const fileInput = ref(null);

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  uploadedFile.value = file;

  const newDocument = {
    id: Date.now(), // Mock ID, using timestamp to simulate uniqueness
    doc_name: file.name,
    mime: file.type,
    size: file.size, // Optional, but useful for display
    blacklisted: false,
    isNew: true,
    file: file
  };

  // Directly push the new document into the currentTicket's documents array
  if (currentTicket.value && currentTicket.value.documents) {
    currentTicket.value.documents.push(newDocument);
  } else {
    // Initialize the documents array if it doesn't exist
    currentTicket.value = { ...currentTicket.value, documents: [newDocument] };
  }
  if (fileInput.value) {
    fileInput.value.value = ''; // Resets the file input element
  }
};

const blacklistDocument = (doc) => {
  // Find the document in the currentTicket's documents array and set its blacklisted property to true
  const docIndex = currentTicket.value.documents.findIndex(d => d.id === doc.id);
  if (docIndex !== -1) {
    currentTicket.value.documents[docIndex].blacklisted = true;
  }
};

const deleteDocument = (document) => {
  // TODO: Add api call logic to delete the document from the server
  // and remove it from the uploadedDocuments array
  if (currentTicket.value && currentTicket.value.documents) {
    currentTicket.value.documents = currentTicket.value.documents.filter(doc => doc.id !== document.id);
    if (!currentTicket.value.deleteDocumentIds) {
      currentTicket.value.deleteDocumentIds = [];
    }
    currentTicket.value.deleteDocumentIds.push(document.id);
  }
};
const delegateTicket = async (ticket, startJob) => {
 // Check if description has been cleared
 if (ticket.originalDescription && !ticket.description) {
    if (!confirm("There was a description, it is now blank, do you wish to continue?")) {
      return;
    }
  }

  // Check if the ticket is marked as urgent
  if (ticket.urgent) {
    if (!confirm("Marked URGENT! The technician will be paged. Would you like to continue?")) {
      return;
    }
  }

  // Ensure a client is selected
  if (!ticket.company_id) {
    alert("Client REQUIRED");
    return;
  }

  // Ensure a ticket type is selected
  if (!ticket.tickettype_id) {
    alert("Ticket Type is required.");
    return;
  }

  // Ensure a technician is assigned
  if (!ticket.tech_id && !startJob && ticket.attachTo == 0) {
    alert("Assigned To (tech) REQUIRED");
    return;
  }
    this.$updateLoading(-1);
   // Proceed with delegation
   try {

    const formData = new FormData();
    Object.keys(ticket).forEach(key => {
      if (key !== 'documents' || key !== 'deleteDocumentIds' || key!='watchers' || key!='reqAssignees') {  // Exclude the documents from regular appending
        formData.append(key, ticket[key]);
      }
    });
    // Append documents
    ticket.documents.forEach(doc => {
      if (doc.isNew) {
        formData.append('ticketdoc[]', doc.file, doc.file.name);
      }
    });

    ticket.deleteDocumentIds.forEach(doc => {
      formData.append('deleteDocumentIds[]', doc);
    });
    ticket.watchers.forEach(watcher => {
      formData.append('watchers[]', JSON.stringify({ id: watcher.id, visible: watcher.visible }));
    });
    ticket.reqAssignees.forEach(reqAssignee => {
      formData.append('reqAssignees[]', reqAssignee.id);
    });
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    formData.append('startJob', startJob);
    const response = await axios.post(`${axios.defaults.baseURL}ticket/delegate`, formData);
    if (response.status === 200) {
      // If delegation is successful, remove the ticket from the totalTickets
      // Remove the ticket from totalTickets
      const index = totalTickets.value.findIndex(t => t.id === ticket.id);
      if (index !== -1) {
        totalTickets.value.splice(index, 1);
        alert('Ticket delegated successfully.');
      }

      // Adjust currentPage and recalculate totalPages
      const totalPages = Math.ceil(totalTickets.value.length / ticketsPerPage);
      if (currentPage.value > totalPages) {
        currentPage.value = totalPages > 0 ? totalPages : 1;
      }

      if (startJob && response.data.ticketId) {
                const oldAppUrl = `https://atms.aimitservices.com/addEdit.php?ticketID=${response.data.ticketId}`;
                window.open(oldAppUrl, '_blank');
            }
    } else {
      alert('Failed to delegate ticket.');
    }
  } catch (error) {
    console.error("There was an error delegating the ticket:", error);
    alert('Error delegating ticket: ' + error.message);
  } finally {
    this.$updateLoading(-1);
  }
  
};

const deleteTicket = (ticketId) => {
  // Confirmation dialog before deleting
  if (!confirm('Are you sure you want to delete this ticket?')) {
    return;
  }

  axios.post(`${axios.defaults.baseURL}ticket/delete`, { delete: ticketId })
    .then(response => {
      alert('Ticket deleted successfully');
      // Code to refresh the ticket list or update the UI accordingly
    })
    .catch(error => {
      console.error('There was an error deleting the ticket:', error);
      alert('Failed to delete ticket');
    });
};
const showTextArea = ref(false);
const activeTextarea = ref(null);

const handleTextCompletion = ({ updatedText, key }) => {
  const fieldMapping = {
    requester: 'description',
    aimNotesPublic: 'delegatornotes',
    privateNotes: 'privateinfo'
  };
  const dbField = fieldMapping[key]; // Get the database field name from the mapping
  const oldText = currentTicket.value[dbField]; // Use the correct field name to get the old value

  // Check if the text actually changed before making an API call
  if (updatedText !== oldText) {
    currentTicket.value[dbField] = updatedText; // Update the value in the current ticket
    changedNotes(dbField); // Call the helper function to update the database
  }

  // Close the modal and reset the active textarea identifier
  showTextArea.value = false;
  activeTextarea.value = null;
};

const changedNotes = (notes) => {
  //need to do a api call that will update the respective notes
  const postData = {
    ticketId: currentTicket.value.id,
    field: notes,
    value: currentTicket.value[notes]
  };
  axios.post('/ticket/update-notes', postData)
    .then(response => {
      console.log('Notes updated successfully:', response.data);
    })
    .catch(error => {
      console.error('Error updating notes:', error);
    });
}

const getTextareaInitialText = () => {
  switch (activeTextarea.value) {
    case "requester":
      return currentTicket.value.description;
    case "aimNotesPublic":
      return currentTicket.value.delegatornotes;
    case "privateNotes":
      return currentTicket.value.privateinfo;
    default:
      return "";
  }
};



const activateTextArea = (key) => {
  activeTextarea.value = key;
  showTextArea.value = true;
};

// const searchReqTypeQuery = ref(currentTicket.value.type);
const showReqTypeDropdown = ref(false);

const filteredReqTypes = computed(() => {
  return currentTicket.value.type
    ? requestTypes.value.filter((e) =>
      e.description
        .toLowerCase()
        .includes(currentTicket.value.type.toLowerCase())
    )
    : requestTypes.value;
});

const setReqType = (type) => {
  currentTicket.value.tickettype_id = type.id;
  currentTicket.value.type = type.description;
  // searchReqTypeQuery.value = type.description;
  showReqTypeDropdown.value = false;
};

const showClientDropdown = ref(false);

const filteredClients = computed(() => {
  return currentTicket.value.client.companyname
    ? clients.value.filter((e) =>
      e.companyname
        .toLowerCase()
        .includes(currentTicket.value.client.companyname.toLowerCase())
    )
    : clients.value;
});

const setClient = (client) => {
  currentTicket.value.company_id = client.id;
  currentTicket.value.client.companyname = client.companyname;
  // searchReqTypeQuery.value = type.description;
  showClientDropdown.value = false;
};
const allRequesters = ref([]);
const requesters = ref([
]);

const showReqDropdown = ref(false);

const filteredRequesters = computed(() => {
  return currentTicket.value.requester
    ? requesters.value.filter((e) =>
      e.name
        .toLowerCase()
        .includes(currentTicket.value.requester.toLowerCase())
    ).slice(0, 20)
    : allRequesters.value;
});

const selectedRequester = ref(null);
const confirmRequesterChange = (req) => {
  if (currentTicket.value.contact_id && currentTicket.value.contact_id != req.id) {
    selectedRequester.value = req;
    console.log('1');
    // Find the old requester by contact_id
    const oldRequester = requesters.value.find(r => r.id === currentTicket.value.contact_id);
    if (oldRequester) {
      currentTicket.value.oldRequester = { id: oldRequester.id, name: oldRequester.name, visible: true };
    } else {
      currentTicket.value.oldRequester = { id: currentTicket.value.contact_id, name: currentTicket.value.requester, visible: true };
    }
    showReqConfirmationModal.value = true;
  } else {
    console.log('2');
    setReq(req);
  }
};

const handleReqConfirmation = (addOldRequesterAsWatcher) => {
  if (addOldRequesterAsWatcher) {
    currentTicket.value.watchers.push(currentTicket.value.oldRequester);
  }
  setReq(selectedRequester.value);
  showReqConfirmationModal.value = false;
};
const setReq = (req) => {
  currentTicket.value.requester = req.name;
  currentTicket.value.contact_id = req.id;
  currentTicket.value.email = req.email;
  // searchReqTypeQuery.value = type.description;
  showReqDropdown.value = false;
  currentTicket.value.requesterInt = ""; // Reset internal requester
  currentTicket.value.alternatephone = req.work_phone ? req.work_phone : req.cell_phone ? req.cell_phone : ''; // Reset phone
  currentTicket.value.extReqEmail = "";
};

const showIntReqDropdown = ref(false);

const filteredIntRequesters = computed(() => {
  return currentTicket.value.requesterInt
    ? intRequesters.value.filter((e) =>
      e.name
        .toLowerCase()
        .includes(currentTicket.value.requesterInt.toLowerCase())
    )
    : [];
});

const setIntReq = (req) => {
  currentTicket.value.requesterInt = req.name;
  currentTicket.value.contact_id = req.id;
  currentTicket.value.requester = "";
  currentTicket.value.email = req.email ? req.email : "";
  currentTicket.value.alternatephone = req.work_phone
    ? req.work_phone
    : req.cell_phone
      ? req.cell_phone
      : "";
  // searchReqTypeQuery.value = type.description;
  showIntReqDropdown.value = false;
  currentTicket.value.extReqEmail = "";
};

const showAssignDropdown = ref(false);

const filteredAssignees = computed(() => {
  return currentTicket.value.assign
    ? employees.value.filter((e) =>
      e.name.toLowerCase().includes(currentTicket.value.assign.toLowerCase())
    )
    : employees.value;
});

const setAssignee = (assignee) => {
  currentTicket.value.assign = assignee.name;
  currentTicket.value.tech_id = assignee.id;
  // searchReqTypeQuery.value = type.description;
  showAssignDropdown.value = false;
};


const closeReqDropdown = () => {
  showReqDropdown.value = false;
}
const closeWatcherDropdown = () => {
  showWatcherDropdown.value = false;
}
const closeReqAssigneeDropdown = () => {
  showRequestAssigneeDropdown.value = false;
}
const closeAssignDropdown = () => {
  showAssignDropdown.value = false;
}
const closeIntReqDropdown = () => {
  showIntReqDropdown.value = false;
}
const closeClientDropdown = () => {
  showClientDropdown.value = false;
}
const closeTypeDropdown = () => {
  showReqTypeDropdown.value = false;
}

const toggleWatcher = () => {
  showWatcher.value = !showWatcher.value;
}
const toggleRequesterAssignee = () => {
  showRequestAssignee.value = !showRequestAssignee.value;
}

// const updatePromisedTime = () => {
//   const today = new Date();
//   today.setDate(today.getDate() + parseInt(selectedDay.value));
//   const dateStr = today.toISOString().slice(0, 10); // Format: YYYY-MM-DD
//   const timeStr = selectedTime.value; // Time as HH:MM

//   currentTicket.value.promised = `${dateStr}T${timeStr}`;
// };


const updatePromisedTime = () => {
  const today = new Date();
  const dayOffset = selectedDay.value === '30' ? 30
    : selectedDay.value === '60' ? 60
      : selectedDay.value === '90' ? 90
        : parseInt(selectedDay.value);
  today.setDate(today.getDate() + dayOffset);

  // Check if the month or day needs a leading zero
  const monthStr = (today.getMonth() + 1).toString().padStart(2, '0');
  const dayStr = today.getDate().toString().padStart(2, '0');

  const dateStr = `${today.getFullYear()}-${monthStr}-${dayStr}`; // Format: YYYY-MM-DD
  const timeStr = selectedTime.value; // Time as HH:MM

  currentTicket.value.promised = `${dateStr}T${timeStr}`;
};

// Watchers to react to changes in 'selectedDay' or 'selectedTime'
watch([selectedDay, selectedTime], () => {
  if (selectedDay.value !== '-1' && selectedTime.value !== '-1') {
    updatePromisedTime();
  }
}, {
  immediate: true // Run on component mount to set initial value
});
const showAddUserModal = ref(false);
const handleAddUserCompletion = ({ newUser }) => {
  console.log(newUser.email)
  currentTicket.value.requester = newUser.firstName + ' ' + newUser.lastName;
  currentTicket.value.email = newUser.email;
  currentTicket.value.alternatephone = newUser.phone;
  currentTicket.value.contact_id = 0;
  // call the api that adds the user
  // call the api to get updated list of requesters
  // Close the modal and reset the active textarea identifier
  showAddUserModal.value = false;
};

const handleFocus = (field) => {
  currentTicket.value['old'+field]=currentTicket.value[field];
  console.log(currentTicket.value['old'+field])
  showConfirmationModal.value = false;
}
const handleBlur = (field) => {
  if (currentTicket.value[field]) {
    fieldToConfirm.value = field;
    valueToConfirm.value = currentTicket.value[field];
    showConfirmationModal.value = true;
  }
}

const handleUpdateUserConfirm = (data) => {
  // Logic to handle the confirmation action
  console.log('return to handleUpdateUserConfirm, make a post api call to update');
  showConfirmationModal.value = false;
}
const handleUpdateUserCancel = (data) => {
  // Logic to handle the cancellation action
  console.log(data.field)
  currentTicket.value[data.field]=currentTicket.value['old'+data.field]
  showConfirmationModal.value = false;
  // if (fieldToConfirm.value === 'email') {
  //   currentTicket.value.email = '';
  // } else if (fieldToConfirm.value === 'alternatephone') {
  //   currentTicket.value.alternatephone = '';
  // }
}

const showWatcherDropdown = ref(false);
const showRequestAssigneeDropdown = ref(false);

// Computed properties for filtered watchers and assignees based on the input
const filteredWatchers = computed(() => {
  // Filter requesters based on the watcher input
  return watchersInput.value
    ? requesters.value.filter((e) =>
      e.name
        .toLowerCase()
        .includes(watchersInput.value.toLowerCase())
    ).slice(0, 20)
    : allRequesters.value;
});

const filteredRequestAssignees = computed(() => {
  // Filter requesters based on the assignee input
  return reqAssigneesInput.value
    ? requesters.value.filter((e) =>
      e.name
        .toLowerCase()
        .includes(reqAssigneesInput.value.toLowerCase())
    ).slice(0, 20)
    : allRequesters.value;
});

// Methods for setting watcher and assignee
const addWatcher = (watcher) => {
  if (!currentTicket.value.watchers.some(w => w.id === watcher.id)) {
    currentTicket.value.watchers.push({ id: watcher.id, name: watcher.name, visible: true });
  }
  showWatcherDropdown.value = false;
  watchersInput.value = '';
};

const addRequestAssignee = (assignee) => {
  if (!currentTicket.value.reqAssignees.some(a => a.id === assignee.id)) {
    currentTicket.value.reqAssignees.push({ id: assignee.id, name: assignee.name });
  }
  showRequestAssigneeDropdown.value = false;
  reqAssigneesInput.value = '';
};

const removeWatcher = (watcher) => {
  const watcherIndex = currentTicket.value.watchers.findIndex(w => w.id === watcher.id);
  if (watcherIndex !== -1) {
    currentTicket.value.watchers[watcherIndex].visible = false;
  }
};

const removeRequestAssignee = (assignee) => {
  currentTicket.value.reqAssignees = currentTicket.value.reqAssignees.filter(a => a.id !== assignee.id);
};

// Methods for toggling the dropdown visibility
const toggleWatcherDropdown = () => {
  showWatcherDropdown.value = !showWatcherDropdown.value;
};

const toggleRequestAssigneeDropdown = () => {
  showRequestAssigneeDropdown.value = !showRequestAssigneeDropdown.value;
};

const notifyCheckboxModel = computed({
  get() {
    return currentTicket.value.emailnotification === 1;
  },
  set(newValue) {
    currentTicket.value.emailnotification = newValue ? 1 : 0;
  }
});

const unPublishedCheckboxModel = computed({
  get() {
    return currentTicket.value.unpublished === 1;
  },
  set(newValue) {
    currentTicket.value.unpublished = newValue ? 1 : 0;
  }
});

const urgencyCheckboxModel = computed({
  get() {
    return currentTicket.value.urgency === 1;
  },
  set(newValue) {
    currentTicket.value.urgency = newValue ? 1 : 0;
  }
});

// Function to handle ticket attachment
function handleRelatedTickets(ticketId) {
  currentTicket.value.attachTo = ticketId;  // Set the selected ticket ID
  showModal.value = false;
}


</script>

<style scoped>
/* Styles are scoped to this component only */
.rounded {
  border-radius: 0.375rem;
  /* 6px */
}

.development {
  background-color: #ccffcc;
}

/* Light Green */
.remote {
  background-color: #ffcccc;
}

/* Light Pink */
.lunch {
  background-color: #ffffcc;
}

/* Light Yellow */
.bench {
  background-color: #93c5fd;
}

/* Light Blue */

.scrollable-requester-list {
  max-height: 400px;
  /* Adjust this value based on your design needs */
  overflow-y: auto;
  /* Enables vertical scrolling */
  border: 1px solid #ccc;
  /* Optional: adds a border around the list */
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
