<template>
    <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-70 z-50 flex justify-center items-center p-4">
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <h2 class="text-lg font-semibold dark:text-gray-100 mb-4">Confirm Information</h2>
        <p class="text-s font-semibold dark:text-gray-100 mb-4">Please confirm the {{ field }}: {{ value }}</p>
        <div class="flex justify-end space-x-2 mt-4">
          <button @click="$emit('confirm', {
            field: field,
            value: value,
          })" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded dark:bg-green-400 dark:hover:bg-green-600">Confirm</button>
          <button @click="$emit('cancel', {
            field: field,
            value: value,
          })" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded dark:bg-red-400 dark:hover:bg-red-600">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: Boolean,
      field: String,
      value: String
    }
  };
  </script>
  