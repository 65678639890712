import { Vue, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import clickOutside from './directives/clickOutside'
import Loader from './services/LoaderOverlay';

// import main css
// import './css/main.css'
import '/index.css'
import store from './store';  // <-- Make sure this import is correct

axios.defaults.baseURL = process.env.VUE_APP_APIURL;
// In your main.js or a dedicated Axios configuration file

// Request interceptor to set the Authorization header dynamically
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Setup Axios interceptor for handling unauthenticated responses
axios.interceptors.response.use(response => {
  // Just return the response if it's successful
  return response;
}, error => {
  // Check if the response is a 401 unauthenticated error
  if (error.response && error.response.status === 401) {
    // Redirect to the /logout route
    router.push('/logout');
  }
  return Promise.reject(error);
});


const app = createApp(App);
app.directive('click-outside', clickOutside); // Use the directive globally
app.use(store);
app.use(router);
app.use(Loader);
app.mount('#app');